import CreateResignation from "Components/E-ExitOffboarding/CreateResignation";
import ResignationApprove from "Components/E-ExitOffboarding/ResignationApprove";
import ResignationComplete from "Components/E-ExitOffboarding/ResignationComplete";
import ResignationaPending from "Components/E-ExitOffboarding/ResignationaPending";
import { SubHeader, Tabs } from "UI_Components";
import React, { useState } from "react";
import { useLocation } from "react-router-dom";

export default function Resignation() {
  let arrData = [
    // { title: "Create Resignation", content: <CreateResignation /> },
    // { title: "Pending", content: <ResignationaPending /> },
    { title: "Complete", content: <ResignationComplete /> },
    { title: "Approval Request", content: <ResignationApprove /> },
  ];
  const [tabs, setTabs] = useState(arrData);
  return (
    <>
       <div className="container-fluid">
    
      <SubHeader location={useLocation()} />

      <div className="mt-3">
          <Tabs tabs={tabs} />
        </div>
    </div>

    </>
  );
}
