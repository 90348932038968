import React, { useState } from "react";
import "../../assets/style/attendance/search-filter.css";
import { CiSearch } from "react-icons/ci";


export const SearchFilter = ({ data, setSearchString, placeholder }) => {
  const [searchTerm, setSearchTerm] = useState(data);

  const handleChange = (e) => {
    setSearchTerm(e.target.value);
    setSearchString(e.target.value.trim());
  };

  const clearSearch = () => {
    setSearchTerm("");
    setSearchString("");
  };

  return (
    <>
      {setSearchString && (
        <div className="position-relative search-filter-wrapper">
          <CiSearch className="search-icon" />
          <input
            className="form-control"
            onChange={handleChange}
            value={searchTerm}
            type="search"
            placeholder={placeholder || "Search"}
          />
          {/* {searchTerm && <FaTimes className="clear-icon" onClick={clearSearch} />} */}
        </div>
      )}
    </>
  );
};
