import AppConstants from "AppConstants";
import * as yup from "yup";

const phoneRegExp = /^(?:(?:\+|00)([1-9][0-9]))?[-. (]*(?:[6-9]\d{2})[-. )]*(\d{3})[-. ]*(\d{4})$/;
const numericRegExp = /^[0-9]+$/;
const indianZipCodeRegex = /^[1-9][0-9]{5}$/;
// const zipCodePattern =
//   /^(?:\d{5}(?:-\d{4})?|[A-Za-z]\d[A-Za-z] \d[A-Za-z]\d|[A-Za-z]{1,2}\d[A-Za-z\d]? ?\d[A-Za-z]{2}|\d{5}|\d{4}|\d{6})$/;
const MAX_FILE_SIZE = 1024 * 1024 * 2;
const VALID_FILE_EXTENSIONS = {
  image: ["jpg", "png", "jpeg"],
  document: ["pdf"],
};

const reservedTLDs = ["test", "example", "invalid", "localhost"];
const forbiddenCharacters = /[#\$%\^&\*_\+\-/}{|]/;
const emojiRegex = /[\p{Emoji_Presentation}\p{Extended_Pictographic}]/u;

const emailSchema = yup
  .string()
  .email("Invalid email format")
  .trim("The email cannot include leading and trailing spaces")
  .strict(true)
  .min(12, "Email is too short")
  .max(320, "Email must not exceed 320 characters")
  .test("strongEmail", "Invalid email format", function (value) {
    if (!value) {
      return true;
    }

    const trimmedValue = value.trim();
    const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailRegex.test(trimmedValue);
  })
  .test("check-tld", "Invalid TLD for email address", (value) => {
    if (!value) return true;

    const tld = value.split("@")[1]?.split(".").pop();
    return !reservedTLDs.includes(tld);
  })
  .test(
    "forbiddenCharacters",
    "Email address must not contain any of these characters: # $ % ^ & * _ + / - } { |",
    function (value) {
      if (!value) {
        return true;
      }
      return !forbiddenCharacters.test(value);
    }
  )
  .test(
    "exactly-two-domains",
    "Email must contain exactly two domain levels (e.g., domain.com or sub.domain.com)",
    (value) => {
      if (!value) return true; // Skip validation if no value (optional)

      // Extract the domain part after the '@'
      const domainPart = value.split("@")[1];
      if (!domainPart) return false; // Invalid if there's no domain part

      // Split the domain part by dots
      const domainParts = domainPart.split(".");

      // Check that there are exactly two domain levels
      return domainParts.length === 2;
    }
  );
function isValidFileType(value) {
  if (!value || !value.name) return false;
  const fileExtension = value.name.split(".").pop().toLowerCase();
  return VALID_FILE_EXTENSIONS.image.includes(fileExtension) || VALID_FILE_EXTENSIONS.document.includes(fileExtension);
}

function isValidImageType(value) {
  if (!value || !value.name) return false;
  const fileExtension = value.name.split(".").pop().toLowerCase();
  return VALID_FILE_EXTENSIONS.image.includes(fileExtension);
}
function isFileSizeValid(value) {
  return value && value.size <= MAX_FILE_SIZE;
}

const mobileNumberValidation = yup
  .string()
  .matches(phoneRegExp, "Phone number is not valid")
  .matches(numericRegExp, "Only numbers are allowed")
  .required("Mobile number is required");

export const OnboardingFormSchema = yup.object().shape({
  profileImage: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidImageType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isFileSizeValid(value);
      }
      return true;
    }),

  firstName: yup
    .string()
    .matches(/^[A-Za-z ]+$/, "Name must contain only letters")
    .min(2)
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true)
    .required("First name is required"),

  middleName: yup
    .string()
    .matches(/^[A-Za-z ]+$/, "Name must contain only letters")
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true),

  lastName: yup
    .string()
    .matches(/^[A-Za-z ]+$/, "Name must contain only letters")
    .min(3)
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true),

  pancard: yup
    .string()
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true)
    .test("", "Invalid pan number", function (value) {
      return AppConstants.panCardRegex.test(value);
    })
    .required("Pan Number is required"),

  aadharcard: yup
    .string()
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true)
    .test("", "Invalid Aadhaar number", function (value) {
      return AppConstants.aadharregex.test(value);
    })
    .required("Aadhaar Number is required"),

  dobDocument: yup
    .date()
    .nullable()
    .test("is-valid-age", "Age must be between 18 to 65 years old", function (value) {
      if (!value) return true;
      const dob = new Date(value);
      const today = new Date();

      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

      const sixtyFiveYearsAgo = new Date();
      sixtyFiveYearsAgo.setFullYear(today.getFullYear() - 65);

      return dob <= eighteenYearsAgo && dob >= sixtyFiveYearsAgo;
    })
    .required("Document date of birth is required"),

  dobActual: yup
    .date()
    .nullable()
    .test("is-valid-age", "Age must be between 18 to 65 years old", function (value) {
      if (!value) return true;
      const dob = new Date(value);
      const today = new Date();

      const eighteenYearsAgo = new Date();
      eighteenYearsAgo.setFullYear(today.getFullYear() - 18);

      const sixtyFiveYearsAgo = new Date();
      sixtyFiveYearsAgo.setFullYear(today.getFullYear() - 65);

      return dob <= eighteenYearsAgo && dob >= sixtyFiveYearsAgo;
    })
    .required("Actual date of birth is required"),

  // gender: yup.string().required(),

  aadharcardfile: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    })
    .required("Aadhaar file is required"),

  pancardfile: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    })
    .required("Pan File is required"),

  email: emailSchema,

  mobileNum: mobileNumberValidation,

  sec_mobileNum: mobileNumberValidation.test(
    "notSameAsMobileNum",
    "Emergency number cannot be the same as the mobile number",
    function (value) {
      const mobileNum = this.parent.mobileNum;
      return value !== mobileNum;
    }
  ),

  family_member_first_name: yup
    .string()
    .matches(/^[A-Za-z ]+$/, "Name must contain only letters")
    .min(2)
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true)
    .required("Family member first name is required"),

  family_member_last_name: yup
    .string()
    .matches(/^[A-Za-z ]+$/, "Name must contain only letters")
    .min(2)
    .trim("The contact name cannot include leading and trailing spaces")
    .strict(true),

  relationship: yup.string().required("Select relationship is required"),

  family_member_dob: yup
    .date()
    .nullable()
    .test("is-past-date", "Date cannot be in the future", function (value) {
      if (!value) return true;
      const selectedDate = new Date(value);
      const currentDate = new Date();
      return selectedDate <= currentDate;
    })
    .required("Date of birth is required"),

  family_member_phone: mobileNumberValidation,

  family_member_email: yup
    .string()
    .email("Invalid email format")
    .trim("The contact email cannot include leading and trailing spaces")
    .strict(true)
    .test("strongEmail", "Invalid email format", function (value) {
      if (!value) {
        return true;
      }
      const trimmedValue = value.trim();
      const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
      return emailRegex.test(trimmedValue);
    }),
});

export const AddressFormSchema = yup.object().shape({
  c_aLine1: yup
    .string()
    .trim("Current address line 1 cannot include leading and trailing spaces")
    .min(5, "Current address line 1 must be at least 5 characters")
    .max(100, "Current address line 1 cannot be longer than 100 characters")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .required("Current address line 1 is required"),
  c_aLine2: yup
    .string()
    .trim("Current address line 2 cannot include leading and trailing spaces")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .max(100, "Current address line 2 cannot be longer than 100 characters"),
  c_aLandmark: yup
    .string()
    .trim("Current address line 2 cannot include leading and trailing spaces")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .max(100, "Current address line 2 cannot be longer than 100 characters"),
  c_aState: yup
    .string()
    .trim("Current address state cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Current address state must only contain letters")
    .required("Current address state is required"),
  c_country: yup
    .string()
    .trim("Current address country cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Current address country must only contain letters")
    .required("Current address country is required"),
  c_aCity: yup
    .string()
    .trim("Current address city cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Current address city must only contain letters")
    .required("Current address city is required"),
  c_aZip: yup
    .string()
    .matches(indianZipCodeRegex, "Invalid ZIP code format")
    .required("Current address ZIP code is required"),

  p_aLine1: yup
    .string()
    .trim("Permanent address line 1 cannot include leading and trailing spaces")
    .min(5, "Permanent address line 1 must be at least 5 characters")
    .max(100, "Permanent address line 1 cannot be longer than 100 characters")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .required("Permanent address line 1 is required"),
  p_aLine2: yup
    .string()
    .trim("Permanent address line 2 cannot include leading and trailing spaces")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .max(100, "Permanent address line 2 cannot be longer than 100 characters"), // Optional field
  p_aLandmark: yup
    .string()
    .trim("Permanent address line 2 cannot include leading and trailing spaces")
    .test("no-emoji", "Emojis are not allowed", (value) => !emojiRegex.test(value))
    .max(100, "Permanent address line 2 cannot be longer than 100 characters"), // Optional field
  p_aState: yup
    .string()
    .trim("Permanent address state cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Permanent address state must only contain letters")
    .required("Permanent address state is required"),
  p_country: yup
    .string()
    .trim("Permanent address country cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Permanent address country must only contain letters")
    .required("Permanent address country is required"),
  p_aCity: yup
    .string()
    .trim("Permanent address city cannot include leading and trailing spaces")
    // .matches(/^[a-zA-Z\s]+$/, "Permanent address city must only contain letters")
    .required("Permanent address city is required"),
  p_aZip: yup
    .string()
    .matches(indianZipCodeRegex, "Invalid ZIP code format")
    .required("Permanent address ZIP code is required"),
  // terms: yup.bool().required().oneOf([true], "Terms must be accepted"),
});

export const EducationFormSchema = yup.object().shape({
  secondaryPer: yup
    .string()
    .trim()
    // .required("Secondary percentage is required")
    .matches(/^\d+(\.\d{1,2})?$/, "Invalid percentage format")
    .test("is-valid-percentage", "Percentage must be between 33 and 100", (value) => {
      if (value) {
        const percentage = parseFloat(value);
        return percentage >= 33 && percentage < 101;
      }
      return true;
    })
    .test("no-leading-zeros", "Leading zeros are not allowed", (value) => /^([1-9]\d*|0)(\.\d{1,2})?$/.test(value)),

  secondaryResult: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Secondary result file is required"),

  seniorPer: yup
    .string()
    .trim()
    // .required("Senior percentage is required")
    .matches(/^\d+(\.\d{1,2})?$/, "Invalid percentage format")
    .test("is-valid-percentage", "Percentage must be between 33 and 100", (value) => {
      if (value) {
        const percentage = parseFloat(value);
        return percentage >= 33 && percentage < 101;
      }
      return true;
    })
    .test("no-leading-zeros", "Leading zeros are not allowed", (value) => /^([1-9]\d*|0)(\.\d{1,2})?$/.test(value)),

  seniorResult: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Senior result is required"),

  bachelorsPer: yup
    .string()
    .trim()
    // .required("Bachelors percentage is required")
    .matches(/^\d+(\.\d{1,2})?$/, "Invalid percentage format")
    .test("is-valid-percentage", "Percentage must be between 33 and 100", (value) => {
      if (value) {
        const percentage = parseFloat(value);
        return percentage >= 33 && percentage < 101;
      }
      return true;
    })
    .test("no-leading-zeros", "Leading zeros are not allowed", (value) => /^([1-9]\d*|0)(\.\d{1,2})?$/.test(value)),

  bachelorsResult: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Bachelors result is required"),
});

const companyNameValidation = yup
  .string()
  .trim("The contact company name cannot include leading and trailing spaces")
  // .matches(/^[a-zA-Z\s]*$/, "Company name can only contain letters")
  .required("Company name is required");

// Custom validation for end date not being less than start date
const dateValidation = yup
  .date()
  .required("This field is required")
  .test("endDateTest", "End date cannot be before start date", function (value) {
    const { start_date } = this.parent;
    return value >= start_date;
  })
  .test("currentDateTest", "End date cannot be in the future", function (value) {
    const today = new Date();
    today.setHours(0, 0, 0, 0); // Ensure time is not considered in comparison
    return value <= today;
  });
export const documentFormSchema = yup.object().shape({
  companyname: companyNameValidation,
  start_date: yup.date().required("Start date is required"),
  end_date: dateValidation,
  offerletter: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Offer letter is required"),
  relievingletter: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Relieving letter is required"),
  resignationletter: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Resignation letter is required"),
  compensation: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Compensation is required"),
  experienceletter: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Experience letter is required"),

  payslip: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("Payslip is required"),

  resignationletter: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  // .required("resignation letter is required"),
  terms: false,
});

export const loginSchema = yup.object({
  email: emailSchema,
  password: yup
    .string()
    .required("Password is required")
    .min(6, "Password must be at least 6 characters")
    .max(30, "Password must not exceed 30 characters")
    // .matches(/[a-z]/, "Password must contain at least one lowercase letter")
    // .matches(/[A-Z]/, "Password must contain at least one uppercase letter")
    // .matches(/\d/, "Password must contain at least one number")
    // .matches(/[@$!%*?&]/, "Password must contain at least one special character (@$!%*?&)")
    .test("noSpaces", "Password must not contain spaces", function (value) {
      if (!value) {
        return true;
      }
      return !/\s/.test(value);
    }),
});

export const NewPasswordSchema = yup.object({
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Your password is too short.")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
      "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and one special case Character"
    ),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref("password")], "Passwords must match")
    .required("Confirm Password is required"),
});

export const AssatSchema = yup.object({
  assetName: yup.string().trim().required("Asset Name is required").max(25, "Asset Name is too long"),
  purchaseDate: yup.date().required("Purchase Date is required").nullable(),
  manufacturer: yup.string().trim().required("Manufacturer is required").max(50, "Manufacturer is too long"),
  serialNumber: yup.string().trim().required("Serial Number is required").max(50, "Serial Number is too long"),
  assetValue: yup
    .number()
    .required("Asset Value is required")
    .typeError("Only numbers are allowed")
    .positive("Asset Value must be a positive number")
    .integer("Asset Value must be a whole number"),
  documentUpload: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
  assetId: yup
    .string()
    .trim()
    .matches(/^[A-Z0-9-]+$/, "Asset ID can only contain uppercase letters, numbers, and dashes")
    .required("Asset ID is required"),
  purchaseFrom: yup.string().required("Purchase from is required"),
  model: yup.string().trim().required("Model is required").max(50, "Model is too long"),
  supplier: yup.string().trim().required("Supplier is required").max(50, "Supplier is too long"),
  warranty: yup
    .date()
    .required("Warranty is required")
    .nullable()
    .test("warranty-test", "Warranty date must be after Purchase date", function (value) {
      const { purchaseDate } = this.parent;
      return purchaseDate && value ? value > purchaseDate : true;
    }),
  description: yup.string().trim().max(1000, "Description is too long"),
  status: yup.string().trim().required("Status is required").max(50, "Status is too long"),
});

export const EditSalarySchema = yup.object().shape({
  employee_name: yup.string().required("Employee name is required"),
  department: yup.string().required("Department is required"),
  pan_number: yup.string().required("PAN number is required"),
  bank_account_number: yup.string().required("Bank account number is required"),
  uan: yup.string(),
  present_day: yup.number().required("Present day is required"),
  joining_date: yup.date().required("Joining date is required"),
  paid_leave: yup.number().required("Paid leave is required"),
  basic_salary: yup.number().required("Basic salary is required"),
  transport_allowance: yup.number().required("Transport allowance is required"),
  total_earning: yup.number(),
  tds: yup
    .number()
    .typeError("TDS must be a valid number")
    .positive("TDS cannot be a negative number")
    .test("is-decimal", "TDS must have at least 2 digits and at most 5 digits including decimals", (value) =>
      /^[0-9]{1,3}(\.[0-9]{1,2})?$/.test(value)
    )
    .required("TDS is required"),
  miscellaneous: yup.number().required("Miscellaneous is required"),
  pf: yup.number().required("PF is required"),
  employeeId: yup.string().required("Employee ID is required"),
  designation: yup.string().required("Designation is required"),
  pay_mode: yup.string().required("Pay mode is required"),
  pf_number: yup.string(),
  gross_salary: yup.number().required("Gross salary is required"),
  total_leave: yup.number().required("Total leave is required"),
  unpaid_leave: yup.number().required("Unpaid leave is required"),
  hra: yup.number().required("HRA is required"),
  bonus: yup.number(),
  net_salary: yup.number().required("Net salary is required"),
  leave_deduction: yup.number().required("Leave deduction is required"),
  advance_salary: yup.number().required("Advance salary is required"),
  esic: yup.number().required("ESIC is required"),
  total_deductions: yup.number(),
  description: yup.string(),
});

export const advanceSalarySchema = yup.object({
  selectType: yup.string().required("Advance Salary Type is required"),
  amount: yup
    .number()
    .transform((value) => (isNaN(value) ? undefined : value)) // Transform non-number values to undefined
    .min(1000, "The amount must be at least 1,000.")
    .max(50000, "The amount cannot exceed 50,000.")
    .typeError("Amount must be a number")
    // .positive("Amount must be a positive number")
    .required("Amount is required"),
  installment: yup.string().required("Installment is required"),
  selectReason: yup.string().min(5, "Please provide at least 5 characters for the reason."),
  selectedFile: yup
    .mixed()
    .test("file-type", "Invalid file type", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value);
      }
      return true;
    })
    .test("file-size", "File size is too large", function (value) {
      if (value && typeof value !== "string") {
        return isValidFileType(value) && isFileSizeValid(value);
      }
      return true;
    }),
});
