import React from "react";
import moment from "moment";
import { dynamicDate, getDayName } from "utils/dateFunctions";

export const WeeklyAttendance = ({ startDate, reportData, message }) => {
  const weekArray = ["M", "Tu", "W", "Th", "F", "S", "S"];

  function getWeekDates() {
    const today = new Date();
    const currentDay = today.getDay();
    const weekStart = new Date(today);

    const diffToMonday = currentDay === 0 ? 6 : currentDay - 1;
    weekStart.setDate(today.getDate() - diffToMonday);

    const weekDates = [];
    for (let i = 0; i < 7; i++) {
      const date = new Date(weekStart);
      date.setDate(weekStart.getDate() + i);
      weekDates.push({
        day: date.toLocaleString("en-US", { weekday: "short" }).toUpperCase(),
        date: date.toISOString().slice(0, 10),
      });
    }

    return weekDates;
  }

  const weekDates = getWeekDates();

  return (
    <>
      <table className="main_table">
        <thead>
          <tr>
            <th scope="col">
              SR. NO.
            </th>
            <th scope="col">Name</th>
            {weekDates.map((weekDate, index) => (
              <th key={index}>
                {moment(weekDate.date).format("DD MMM")}
                <br /> {weekDate.day}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {reportData &&
            reportData.map((employee, index) => (
              <tr key={employee.employeeID}>
                <td>{index + 1}</td>
                <td>
                  {employee.firstname} {employee.lastname}
                </td>
                {weekDates.map((weekDate, weekIndex) => (
                  <td key={weekIndex}>
                    {employee.attendance &&
                      employee.attendance.map((attendance) => {
                        const attendanceDate = new Date(attendance?._doc?.date);
                        if (!isNaN(attendanceDate.getTime())) {
                          if (attendanceDate.toISOString().slice(0, 10) === weekDate.date) {
                            return (
                              <div key={attendance.in}>
                                <p style={{ color: `#${attendance?.color}` }}>{attendance?.leave_type}</p>
                              </div>
                            );
                          }
                        }
                        return null;
                      })}
                  </td>
                ))}
              </tr>
            ))}
        </tbody>
      </table>
      {reportData === undefined && (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "4rem",
            flexDirection: "column",
            fontFamily: "cursive",
            fontSize: "1.8rem",
          }}
        >
          {message}
        </div>
      )}
    </>
  );
};
