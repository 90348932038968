
import React, { useEffect, useState } from "react";
import { Breadcrumb } from "rsuite";
import { SCard, Table } from "UI_Components";
import useremployee from "../../../assets/images/useremployee.png";
import LeaveEmployee from "../../../assets/images/LeaveEmployee.png";
import EmployeeOnboarding from "../../../assets/images/EmployeeOnboarding.png";
import ProbationEmployee from "../../../assets/images/ProbationEmployee.png";
import SelectPickerInput from "common/components/SelectPicker";
import { SearchFilter } from "Components/AttendaceReport";
import apiManager from "networking/baseAPIManager";
import { Dropdown } from "common/components";

const EmployeeAttendance = () => {
  const [loading, setLoading] = useState(false);
  const [attendanceData, setAttendanceData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const currentYear = new Date().getFullYear();
  const [selectedYear, setSelectedYear] = useState(currentYear);
    const [selectedMonth, setSelectedMonth] = useState("");
  const [attendanceCard,setAttendanceCard] = useState("");
  const moment = require("moment");

  const formatDate = (date) => moment(date).format("DD MMM YYYY");
  const formatTime = (time) => moment(time).format("hh:mm A");
  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };
   const getMonthOptions = () => {
      return moment.months().map((month) => ({
        value: month,
        label: month,
      }));
    };
  const tools = [
    {
      cardContent: "10 / 40",
      cardTitle: "Total Hours Week",
      image: useremployee,
    },
    {
      cardContent: "10 / 40",
      cardTitle: "Overtime this Month",
      image: LeaveEmployee,
    },
    {
      cardContent: "30: min  / 9",
      image: EmployeeOnboarding,
      cardTitle: "Total Break Time",
    },
    {
      cardContent: "75 / 98",
      image: ProbationEmployee,
      cardTitle: "Total Hours Month",
    },
  ];

  const columns = [
    {
      header: "Date",
      field: "date",
      customHtml: (data) => formatDate(data.date),
    },
    {
      header: "Check In",
      field: "checkIn",
      customHtml: (data) => {
        const checkIn = data.checkDetails?.[data.checkDetails.length - 1]?.checkIn;
        return checkIn ? formatTime(checkIn) : "-";
      },
    },
    {
      header: "Check Out",
      field: "checkOut",
      customHtml: (data) => {
        const checkOut = data.checkDetails?.[data.checkDetails.length - 1]?.checkOut;
        return checkOut ? formatTime(checkOut) : "-";
      },
    },
    { header: "Break Time", field: "breaktime" },
    { header: "Over Time", field: "overtime" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        const statusColor =
          data.status?.toLowerCase() === "present"
            ? "approvedClass"
            : data.status?.toLowerCase() === "absent"
            ? "rejectedClass"
            : "actionsPending";
            return <span className={statusColor}>{data.status}</span>;
      },
    },
    { header: "Production Hours", field: "worktime" },
    // { header: "Production Hours ", field: "leave_days" }
  ];

  useEffect(() => {
    fetchEmployeeAttendanceCard();
    fetchEmployeeAttendanceData();
  }, [selectedYear, selectedMonth]);

  const fetchEmployeeAttendanceData = () => {
    setLoading(true);
    apiManager
      .EmployeeAttendance()
      .then((response) => {
        setLoading(false);
        const allData = response.data.data || [];
      const filteredData = allData.filter((item) => {
        const itemYear = moment(item.date).year();
        const itemMonth = moment(item.date).format("MMMM");
        return (
          itemYear === selectedYear &&
          (selectedMonth ? itemMonth === selectedMonth : true)
        );
      });
        setAttendanceData(filteredData);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  const fetchEmployeeAttendanceCard = () => {
    setLoading(true);
    apiManager
      .EmployeeaAttendanceCard()
      .then((response) => {
        setLoading(false);
       
        setAttendanceCard();
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const handleYearChange = (value) => {
    const newSelectedYear = value != null ? parseInt(value) : currentYear;
    setSelectedYear(newSelectedYear);
  };

  const years = Array.from({ length: 3 }, (_, index) => currentYear - index);
  let dropdownData = years.map((year) => ({
    label: year.toString(),
    value: year.toString(),
  }));

  const filteredData = attendanceData
    .filter((item) =>
      item.firstname?.toLowerCase().includes(searchString.toLowerCase())
    )
    .sort((a, b) => new Date(b.date) - new Date(a.date));

  return (
    <>
      <div className="container-fluid">
    {/* <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} /> */}
      <div className="head-title-bg head-title-row">
        <div className="page-title col-12">
          <Breadcrumb separator=">">
            <Breadcrumb.Item
              href="/employee"
            >
              Home
            </Breadcrumb.Item>
            <Breadcrumb.Item
              active
            >
              Attendance
            </Breadcrumb.Item>
          </Breadcrumb>
        </div>
      </div>
  
          <SCard tools={tools} />
     
  <div className="card-top align-items-center">
      
      <div className="card-head-left">
            <div className="card-title">
            Mark Attendance
            </div>
            <div className="sub-title">
            {moment().format("DD MMM YYYY")}
            </div>
          </div>

        <div className='card-head-right'>

        <Dropdown
                parentclasses=""
                value={selectedMonth}
                onChange={handleMonthChange}
                options={getMonthOptions()}
                placeholder="Month"
                sidelabel
              />
 <SelectPickerInput
                  sidelabel="Select Year"
                  defaultselect={currentYear}
                  data={dropdownData}
                  value={selectedYear}
                  valueKey={"value"}
                  className="w-100"
                  onChange={handleYearChange}
                />


{/* 
        <SearchFilter
                  data={searchString}
                  setSearchString={setSearchString}
                  placeholder={"Search Employee By Name"}
                />
              
          
                <SelectPickerInput sidelabel="Select Year" className="w-100" /> */}
        </div>
      
    </div>



    <div class="card">
      <Table
        columns={columns}
        // Data={dummyData}
        btn_name="view"
        LeaveTableHeight="LeaveTableHeight"
        custom_btn="custom_btn"
        custom_btn2="custom_btn2"
      />
    </div>
      </div>
    </>
  )
}

export default EmployeeAttendance
