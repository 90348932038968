import { Button } from "common/components";
import React from "react";
import { Message } from "rsuite";

function
  ConfirmationModal({ setOpen, handleLogout, setConfirm, setFreshers, Message, cancel }) {
  const handleCancel = () => {
    setOpen(false);
    setFreshers(true);

  };

  const handleConfirm = () => {
    setOpen(false);
    setConfirm(true);
  };

  return (
    <>

      <div className="text-center p-5">
        <div className="col-md-12 mt-2">
          <h5>{`${Message || "Are You Sure ? "}`} </h5>
        </div>
        <div className="d-flex gap-3 w-100 justify-content-center mt-4">

          <Button
            className="yes-btn"
            label="Yes"
            onClick={() => {
              handleConfirm();
            }}
          />


          <Button className="delete-submit-btn " onClick={() => handleCancel()} label={!cancel ? "Cancel" : "No"} />

        </div>
      </div>

    </>
  );
}

export default ConfirmationModal;
