import { Table } from "UI_Components";
import { Button, CoreLoader } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Pagination } from "rsuite";
import { IoEyeOutline } from "react-icons/io5";


export default function HrtoolDashoardTable() {
  const [loading, setLoading] = useState(false);
  const [empListData, setEmpListData] = useState([]);
  const navigate = useNavigate();

  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 6;

  useEffect(() => {
    if (empListData && empListData?.length > 0 && itemsPerPage > 0) {
      const start = (activePage - 1) * itemsPerPage;
      const end = start + itemsPerPage;
      const SliceData = empListData?.slice(start, end);

      const paginatedData = SliceData?.map((item, index) => ({
        ...item,
        sno: start + index + 1,
      }));

      setDisplayedData(paginatedData);
    }
  }, [activePage, empListData, itemsPerPage]);

  const customHtml = (data) => {
    return <IoEyeOutline className="Viewbtn" onClick={() => handleItemClick(data)} />
  };
  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");

  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "firstname" },

    { header: "Mobile.No", field: "mobile_number" },
    { header: "Designation", field: "designation", customHtml: (data) => data.designation || "N/A" },
    { header: "Onboarding Date", field: "createdAt", customHtml: (data) => dateHtml(data.createdAt) },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        let statusColor;
        let statusText;

        switch (data.status?.toLowerCase()) {
          case "completed":
            statusColor = "approvedClass";
            statusText = data.status;
            break;
          case "pending":
            statusColor = "rejectedClass";
            statusText = data.status;
            break;
          case "approved":
            statusColor = "actionsPending";
            statusText = "In Process";
            break;
          default:
            statusColor = "actionsINFO";
            statusText = "N/A";
            break;
        }

        return <span className={statusColor}>{statusText}</span>;
      },
    },
    { header: "Actions", field: "actions", btnType: "primary", customHtml },
  ];

  const handleItemClick = (data) => {
    navigate(`/hrtools/employee-onboarding/candidatePreview/${data._id}`);
  };

  useEffect(() => {
    employeeList();
  }, []);
  const employeeList = () => {
    setLoading(true);
    apiManager

      .employeeList()
      .then((response) => {
        setLoading(false);
        setEmpListData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
 

          <div className="responsive-table">
          <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" className="pointer tdStatus" />

          </div>
          <Pagination
  prev
  last
  next
  first
  size="sm"
  total={empListData?.length}
  limit={itemsPerPage}
  activePage={activePage}
  onChangePage={setActivePage}
/>

        <CoreLoader show={loading} disableLines />
      
    </>
  );
}
