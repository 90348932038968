import { SearchFilter } from "Components/AttendaceReport";
import { Table } from "UI_Components";
import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown, Label } from "common/components";
import React, { useEffect, useState } from "react";
import ManageFundView from "./ManageFundView";
import apiManager from "networking/baseAPIManager";
import moment from "moment";
import { Pagination } from "rsuite";
export default function EmployeeManageFund() {
  const [searchString, setSearchString] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [viewdata, setViewdata] = useState();
  const [loading, setLoading] = useState(false);

  const [payrollListData, setpayrollListData] = useState([]);
  const [viewButtonClicked, setViewButtonClicked] = useState(false);
  const [selectedYear, setSelectedYear] = useState("");
  const [selectedMonth, setSelectedMonth] = useState("");
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    return Array.from({ length: 6 }, (_, i) => currentYear - i).map((year) => ({
      value: year,
      label: year.toString(),
    }));
  };
  const getMonthOptions = () => {
    return moment.months().map((month, index) => ({
      value: month,
      label: month,
    }));
  };



  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
  };

  const handleMonthChange = (event) => {
    setSelectedMonth(event.target.value);
  };

  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" />;
  };
  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Name", field: "empname" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Present Days", field: "presentDay" },
    {
      header: "Status",
      field: "salary_status",
      customHtml: (data) => {
        const statusColor = data.payment_status === true ? "green" : "red";
        const paymentStatus = data.payment_status === true ? "paid" : "unpaid";
        return <span style={{ color: statusColor }}>{paymentStatus}</span>;
      },
    },
    { header: "Deduction", field: "totalDeductions" },
    {
      header: "Net Pays",
      field: "netSalary",
      customHtml: (data) => {
        const netPay = data.netSalary ? data.netSalary.toFixed(2) : "0.00";

        return <span>{netPay}</span>;
      },
    },
    { header: "Payment Mode", field: "payment_mode" },
    { header: "", field: "actions", btnType: "primary", customHtml },
  ];

  const handleItemClick = (data) => {
    setIsOpen(true);
    setViewdata(data);
    setViewButtonClicked(true);
  };
  useEffect(() => {
    payrollList();
  }, [selectedMonth, selectedYear]);
  const payrollList = () => {
    setLoading(true);
    const monthNumber = moment().month(selectedMonth).format("M");
    apiManager

      .salaryList({ month: monthNumber, year: selectedYear })
      .then((response) => {
        setLoading(false);

        setpayrollListData(response?.data?.SalaryList);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };
  useEffect(() => {
    updateDisplayedData();
  }, [payrollListData, activePage, searchString]);

  const updateDisplayedData = () => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = payrollListData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));

    let filteredData = paginatedData.filter(
      (item) =>
        item.empname.toLowerCase().includes(searchString.toLowerCase()) ||
        item.employeeID.toLowerCase().includes(searchString.toLowerCase())
    );

    setDisplayedData(filteredData);
  };
  //prve month and salary
  useEffect(()=>{
    const previousMonth = moment().subtract(1, 'months').format('MMMM');
    const previousYear = moment().subtract(1, 'years').format('YYYY');
    setSelectedMonth(previousMonth)
    setSelectedYear(previousYear)
  },[])
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <div className="container-fluid">
        <div className="row m-0 p-3">
          <div className=" col-lg-4 col-md-6 col-sm-12 px-2">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Search Employee By Name/ID"}
            />
          </div>

          <div className="col-lg-8  col-md-6 col-sm-12 d-flex justify-content-end  gap-3">
            <div>
              <Dropdown
                value={selectedMonth}
                onChange={handleMonthChange}
                options={getMonthOptions()}
                placeholder="Month"
              />
            </div>
            <div>
              <Dropdown
                value={selectedYear}
                onChange={handleYearChange}
                options={getYearOptions()}
                placeholder="Year"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 px-2  tableContainer">
            <Table Data={displayedData} columns={columns} LeaveTableHeight="LeaveTableHeight" />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={payrollListData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="lg" title="Employee Info">
        <ManageFundView
          data={viewdata}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          showPaySlipButton={viewButtonClicked}
          setmsgType={setmsgType}
          setShowMSG={setShowMSG}
        />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </>
  );
}
