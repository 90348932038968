import React from "react";
import Style from "./css/CardWrapper.module.css";

const CardWrapper = (props) => {
  const { children, className, onClick } = props;
  return (
   <>
    <div className="col-2">
    <div className={`card EmployeeCard`} onClick={onClick}>
      {children}
    </div>
    </div>
   </>
  );
};

export default CardWrapper;
