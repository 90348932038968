import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Tabs from "../../../UI_Components/Tabs";
import SubHeader from "../../../UI_Components/SubHeader";
import Inprocess from "../../../Components/OnboardingStatus/Inprocess";
import Completed from "../../../Components/OnboardingStatus/Completed";
import Pending from "../../../Components/OnboardingStatus/Pending";
import apiManager from "networking/baseAPIManager";
import { CoreLoader } from "common/components";
import isResourceAvailable from "utils/permit_util";
import "../../../assets/style/Responsive.css";
const EmployeeOnboarding = () => {
  const [employeeOnboardingData, setEmployeeOnboardingData] = useState([]);
  const [loading, setLoading] = useState(false);

  const showStartOnboarding = isResourceAvailable("onboarding", "create");
  const employeesList = () => {
    setLoading(true);
    apiManager
      .employeeList()
      .then((response) => {
        setLoading(false);
        setEmployeeOnboardingData(response.data);
      })
      .catch((error) => {
        setLoading(false);
        console.log(error);
      });
  };

  useEffect(() => {
    employeesList();
  }, []);

  let trueStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "completed");

  let approvedData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "approved");

  let pendingStatusData = employeeOnboardingData.filter((data) => String(data.status).toLowerCase() == "pending");

  let arrData = [
    { title: "Pending", content: <Pending data={pendingStatusData} /> },
    { title: "In Process", content: <Inprocess data={approvedData} /> },
    { title: "Completed", content: <Completed data={trueStatusData} /> },
  ];

  const [tabs, setTabs] = useState(arrData);

  useEffect(() => {
    setTabs([...arrData]);
  }, [employeeOnboardingData]);

  const navigate = useNavigate();
  return (
    <>
      <div className="container-fluid">
        <SubHeader location={useLocation()} />
       <div className="tab-container">
        <div className="start_onboarding">
        <button
            className="button"
            onClick={() => navigate("/hrtools/employee-onboarding/candidatedetails")}>
            Start Onboarding
          </button>
        </div>
        <Tabs tabs={tabs} /> 
       </div>
          </div>
      <CoreLoader show={loading} />
    </>
  );
};

export default EmployeeOnboarding;
