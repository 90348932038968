import React, { useId } from "react";
import styles from "./css/input.module.css";
import { SelectPicker } from "rsuite";

function SelectPickerInput(props) {
  const {
    id,
    data,
    sidelabel,
    label,
    touched,
    placeholder,
    defaultselect,
    error,
    required,
    selectClassName,
    value,
    onChange,
    valueKey,
  } = props;
  let uniqueId = useId();
  const placeholderText = placeholder || "Select";
  let propsData = { ...props };
  delete propsData.label;
  delete propsData.id;
  return (
    <div className={`form-outline p-0 mt-0  ${styles.maininputparent}`}>
 
        <label className={sidelabel ? styles.label : "normalBold"} htmlFor={"input" + uniqueId}>
          {label} {required && <span className="astricRequired">*</span>}
        </label>

        <SelectPicker
          key={selectClassName}
          label={sidelabel}
          className={`${styles.input}`}
          id={id || "input" + uniqueId}
          data={data}
          value={value}
          placeholder={placeholderText}
          valueKey={valueKey || "_id"}
          onChange={(value, event) => onChange(value, event, id)}
        />

        {touched && error && <span className={styles.error}>{error}</span>}
 
    </div>
  );
}

export default SelectPickerInput;
