import React from "react";
import {
  AddressDetails,
  Candidatedetails,
  EducationDetail,
  Documents,
  CandidatePreview,
  ExperienceDetails,
} from "./forms";
import { useParams } from "react-router-dom";
// import EducationDetail from "./EducationDetail";
const UserDetail = () => {
  const params = useParams();
  const { activePage } = params;
  let renderForms = {
    candidatedetails: Candidatedetails,
    addressdetails: AddressDetails,
    educationdetails: EducationDetail,
    documentDetails: Documents,
    candidatePreview: CandidatePreview,
    experiencedetails: ExperienceDetails,
  };



  const renderForm = () => {
    let Component = renderForms[activePage];
    if (!Component) return;
    return (
      <>
       <div className="container-fluid">
       <Component />
       </div>
      
      </>
    );
  };
  return (
    <>
    <div className="container-fluid">
      <div class="head-title-bg mb-3">
        <div class="page-title">Candidate Onboarding</div>
        </div>
        </div>
      {renderForm()}
    </>
  );

};

export default UserDetail;
