import React, { useEffect, useState } from "react";
import { Button, CommonModal, CoreLoader } from "common/components";
import ConfirmationModal from "common/components/ConfirmationModal";
import moment from "moment";
import apiManager from "networking/baseAPIManager";

const EmployeeAdvanceSalaryView = ({ Data, openModal, closeModal, fetchapi }) => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [cancele_id, setCancel_id] = useState("");
  const [confirm, setConfirm] = useState(false);

  

  return (
    <CommonModal open={openModal} onClose={closeModal} title="Advance Salary Details">
      <div className="container">
        {showMSG && <div className={`alert alert-${msgType}`}>{showMSG}</div>}
        <div className="row">
          <div className="col-lg-6">
           
     
              <div>Advance Salary Type:{Data.advance_salary_type}</div>
              
    
         
            {/* <div>Applied Date: {moment(Data.createdAt).format("L")}</div> */}
            <div>Amount: ₹{Data.amount}</div>
            <div>Instalment: ₹{Data.instalment}</div>
            <div>EMI Amount: ₹{Data.emi_amount}</div>

            <div>Instalment: ₹{Data.instalment}</div>

            <div>Attachment: ₹{Data.attachment}</div>
            <div>Reason: {Data.reason}</div>


          </div>
        </div>
        <CoreLoader show={loading} />
      </div>
    </CommonModal>
  );
};

export default EmployeeAdvanceSalaryView;
