import { AlertMessage, Button, CommonModal, CoreLoader, Dropdown } from "common/components";
import AssetInfo from "Components/AssetManagement/AssetInfo";
import { SearchFilter } from "Components/AttendaceReport";
import apiManager from "networking/baseAPIManager";
import React, { useEffect } from "react";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { Pagination } from "rsuite";
import { SubHeader, Table } from "UI_Components";

const Inventory = () => {
  const [inventoryData, setInventoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();
  const [searchString, setSearchString] = useState();
  const [statusTypeData, setStatusTypeData] = useState();
  const [filteredApprovalData, setFilteredApprovalData] = useState(inventoryData);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);

  const StatusType = [
    { key: "all", value: "all" },
    { key: "assigned", value: "assigned" },
    { key: "unassigned", value: "unassigned" },
    { key: "repair", value: "repair" },
  ];

  const customHtml = (data) => {
    return <Button onClick={() => handleRowClick(data)} label="View" />;
  };
  const columns = [
    { header: "S.No", field: "" },
    {
      header: "Asset Name",
      field: "assetname",
      customHtml: (data) => {
        let BASE_URL = process.env.REACT_APP_BASE_URL;
        return (
          <>
            <span className="pt-3">
              <img src={`${BASE_URL}/${data.image}`} width="30" height="30" className="rounded-circle" />
            </span>
            <span className="ps-2 text-black pt-3">{data.assetname}</span>
          </>
        );
      },
    },
    { header: "Asset ID", field: "assetId" },
    { header: "Manufacturer", field: "manufacturer" },
    { header: "Supplier", field: "supplier" },
    { header: "Warranty " },
    { header: "Warranty End", field: "warranty" },
    {
      header: "Status",
      field: "status",
      customHtml: (data) => {
        let statusColor;
        let statusIndicator;

        if (data.status.toLowerCase() === "assigned") {
          statusColor = "green";
          statusIndicator = "●";
        } else if (data.status.toLowerCase() === "repair") {
          statusColor = "red";
          statusIndicator = "●";
        } else {
          statusColor = "blue";
          statusIndicator = "●";
        }

        return (
          <>
            <h6 className="pt-2">
              <span style={{ color: statusColor }}>{statusIndicator}</span>
              <span className="ps-2 text-black">{data.status}</span>
            </h6>
          </>
        );
      },
    },
    { header: "Action", field: "action", btnType: "primary", customHtml },
  ];

  const InventoryList = () => {
    setLoading(true);
    apiManager
      .assetInvantory()
      .then((response) => {
        setInventoryData(response.data.Asset);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    InventoryList();
  }, []);

  const handleRowClick = (asset) => {
    setIsOpen(true);
    setSelectedAsset(asset);
  };
  useEffect(() => {
    let result = inventoryData;
    if (searchString) {
      result = result.filter((rowObj) => {
        return (
          rowObj.assetname.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.assetId.toLowerCase().includes(searchString.toLowerCase())
        );
      });
    }
    if (statusTypeData && statusTypeData !== "all") {
      result = result.filter((rowObj) => rowObj.status.toLowerCase() === statusTypeData);
    }
    setFilteredApprovalData(result);
    setActivePage(1);
  }, [inventoryData, searchString, statusTypeData]);
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
      <SubHeader location={useLocation()} />
      <div className="container-fluid">
        <div className="row m-0 p-0">
          <div className="col-lg-3 mt-">
            <SearchFilter
              data={searchString}
              setSearchString={setSearchString}
              placeholder={"Asset Name or Asset Id"}
            />
          </div>
          <div className="col-lg-3 mt-4">
            <Dropdown options={StatusType} onChange={(e) => setStatusTypeData(e.target.value)} />
          </div>
        </div>
        <div className="row">
          <div className="col-lg-12 px-2 pt-3 tableContainer">
            <Table columns={columns} Data={displayedData} />
          </div>
          <div className="pt-4 d-flex justify-content-end">
            <Pagination
              prev
              last
              next
              first
              size="sm"
              total={filteredApprovalData.length}
              limit={itemsPerPage}
              activePage={activePage}
              onChangePage={setActivePage}
            />
          </div>
        </div>
      </div>
      <CommonModal open={isOpen} onClose={() => setIsOpen(false)} size="lg" title="Asset Info">
        <AssetInfo
          Data={selectedAsset}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          setmsgType={setmsgType}
          setShowMSG={setShowMSG}
        />
      </CommonModal>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default Inventory;
