import React, { useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { subHeaderMenu } from "../../common/json/MenuContent";
import { current } from "@reduxjs/toolkit";
import { BsCheckLg } from "react-icons/bs";
import { Breadcrumb } from "rsuite";
import isResourceAvailable from "utils/permit_util";
const SubHeader = ({ location, mode }) => {
  const pathname = location.pathname;
  const rolePermission = isResourceAvailable("role");
  const pathnameMode = location.pathname + "-" + mode;
  const params = useParams();
  const current_title = mode
    ? subHeaderMenu.filter((menu) => pathnameMode === menu.path)
    : subHeaderMenu.filter((menu) => pathname === menu.path);
  const handleRoleWise = () => {
    if (rolePermission === "ADMIN") {
      return "/hrtools";
    } else if (
      rolePermission === "IT" ||
      rolePermission === "SALES" ||
      rolePermission === "ACCOUNTS" ||
      rolePermission === "RECRUITMENT"
    ) {
      return "emloyee/dashboard";
    }
  }

  return (
    <>
      <div className="head-title-bg">
        <div className="page-title">


      
              <Breadcrumb  separator= {`${current_title[0]?.title ==="HR Dashboard" ?"":">"}`}>
              {current_title[0]?.title ==="HR Dashboard" ?<></>:
                <Breadcrumb.Item
                 href={handleRoleWise() || "#"}

                >
                  Home
                </Breadcrumb.Item>}
                <Breadcrumb.Item
                  active
           
                >
                  {current_title?.length > "0" ? current_title[0]?.title : !params ? "Landing Page" : "Holiday calender"}
                </Breadcrumb.Item>
              </Breadcrumb>
          
    
        </div>
      </div>
    </>
  );
};

export default SubHeader;
