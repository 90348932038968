
import { AlertMessage, Button, CommonModal } from "common/components";
import SelectPickerInput from "common/components/SelectPicker";
import { SearchFilter } from "Components/AttendaceReport";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { FaEye } from "react-icons/fa";
import { Breadcrumb } from "rsuite";
import { Table } from "UI_Components";
import PayrollForm from "./PayrollForm";

const Payroll = () => {
  const [loading, setLoading] = useState(false);
  const [payrollData, setPayrollData] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);

  const moment = require("moment");

 
  const handleItemClick = (data) => {
    setModalContent(data); 
    setIsOpen(true); 
  };

  
  const closeModal = () => {
    setIsOpen(false);
    setModalContent(null); 
  };

  
  const customHtml = (data) => (
    <Button
      onClick={() => handleItemClick(data)}
      label={
        <>
          <FaEye style={{ marginRight: "5px" }} />
        </>
      }
    />
  );

  // Table columns
  const columns = [
    { header: "Month", field: "month" },
    { header: "Present Days", field: "presentDay" },
    { header: "Absent Days", field: "end_date" },
    { header: "Net Pays", field: "netSalary" },
    { header: "Deduction", field: "totaldeduction" },
    {
      header: "Status",
      field: "salary_status",
      customHtml: (data) => {
        const statusColor =
          data.salary_status.toLowerCase() === "paid"
            ? "green"
            : data.salary_status.toLowerCase() === "rejected"
            ? "red"
            : "blue";
        return <span style={{ color: statusColor }}>{data.salary_status}</span>;
      },
    },
    { header: "Actions", field: "actions", customHtml: customHtml },
  ];

  
  useEffect(() => {
    EmployeePayrollList();
  }, []);

  const EmployeePayrollList = () => {
    setLoading(true);
    apiManager
      .EmployeePayroll()
      .then((response) => {
        setLoading(false);
        setPayrollData(response?.data?.SalaryList || []);
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  return (
    <>
      <div className="container-fluid px-2">
        <div className="row pt-3 m-0 p-0 ">
          <div className="col-lg-8 col-md-8 col-sm-12 attendancereport gap-2 ">
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                href="/employee"
                style={{
                  color: "gray",
                  fontSize: "1.25rem",
                  textDecoration: "none",
                }}
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
                style={{ color: "orange", fontSize: "1.25rem" }}
              >
                Payroll
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="border rounded shadow p-1 px-3 py-3 bg-white m-0 p-0 mx-2 mb-2">
          <div className="row m-0 p-0">
            <div className="col-6 flex justify-between">
              <div className="left-section">
                <h2 className="font-bold text-xl" style={{ textAlign: "justify" }}>
                  Payroll
                </h2>
              </div>
            </div>
          </div>
          <div className="row m-0 p-0">
            <Table
              columns={columns}
              Data={payrollData}
              btn_name="view"
              LeaveTableHeight="LeaveTableHeight"
              custom_btn="custom_btn"
              custom_btn2="custom_btn2"
            />
          </div>
        </div>
      </div>
      {/* Modal for payroll details */}
      <CommonModal open={isOpen} onClose={closeModal} size="lg" title="Employee Info">
        <PayrollForm data={modalContent} />
      </CommonModal>
    </>
  );
};

export default Payroll;
