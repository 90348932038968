import {
  AlertMessage,
  Button,
  CoreLoader,
  Dropdown,
  Input,
  Label,
  Textarea,
} from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import { Breadcrumb } from "rsuite";

const EmployeeCreateResignation = () => {
  const [resignationTypeOptions, setResignationTypeOptions] = useState([]);
  const [resignationDetail, setResignationDetail] = useState({
    resignationType: "",
    noticeDate: "",
    resignationDate: "",
    reason: "",
  });

  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  useEffect(() => {
    const today = new Date();
    const resignationDate = today.toISOString().split("T")[0];
    const noticeDate = new Date(today.setMonth(today.getMonth() + 1))
      .toISOString()
      .split("T")[0];

    setResignationDetail((prevState) => ({
      ...prevState,
      noticeDate,
      resignationDate,
    }));

    getResignationTypeOptions();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setResignationDetail((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const getResignationTypeOptions = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes("offboarding")
      .then((response) => {
        setResignationTypeOptions(response?.data?.data);


        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching resignation type options:", error);
        setLoading(false);
      });
  };

  const validateForm = () => {
    let errors = {};
    let isValid = true;

    if (!resignationDetail.resignationType) {
      errors.resignationType = "Resignation Type is required";
      isValid = false;
    }

    if (!resignationDetail.noticeDate) {
      errors.noticeDate = "Notice Date is required";
      isValid = false;
    }

    if (!resignationDetail.resignationDate) {
      errors.resignationDate = "Resignation Date is required";
      isValid = false;
    }

    if (resignationDetail.reason && resignationDetail.reason.length < 10) {
      errors.resignationReason =
        "Reason must be at least 10 letters if provided";
      isValid = false;
    }

    setErrors(errors);
    return isValid;
  };

  const createResignation = (e) => {
    e.preventDefault();

    if (!validateForm()) {
      return;
    }

    const payload = {
      resignation_type: resignationDetail.resignationType,
      notice_date: resignationDetail.noticeDate,
      resignation_date: resignationDetail.resignationDate,
      reason: resignationDetail.reason,
    };

    setLoading(true);
    apiManager
      .CreateOffboarding(payload)
      .then((res) => {
        setLoading(false);
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
        // navigate("/hrtools/e-exit/Offboarding#pending");
        setResignationDetail({
          resignationType: "",
          noticeDate: "",
          resignationDate: "",
          reason: "",
        });
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
      });
  };
  return (
    <>
      <div>
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} /></div>
      <div className="container-fluid">
        <div className="head-title-bg head-title-row">
          <div className="page-title col-12">
            <Breadcrumb separator=">">
              <Breadcrumb.Item
                href="/employee"
              >
                Home
              </Breadcrumb.Item>
              <Breadcrumb.Item
                active
              >
                E-Exit
              </Breadcrumb.Item>
            </Breadcrumb>
          </div>
        </div>
        <div className="card mt-3">
          <h4 className="card-title mb-3">E-Exit</h4>
          <form>
            <div className="row mb-3">
              {/* Resignation Type */}
              <div className="col-md-4">
                <div className="form-group">
                  <Label label="Resignation Type" />
                  <Dropdown
                    value={resignationDetail.resignationType}
                    name="resignationType"
                    onChange={handleInputChange}
                    options={resignationTypeOptions}
                    placeholder="Select Resignation Type"
                  />
                  {errors.resignationType && (
                    <div className="text-danger">{errors.resignationType}</div>
                  )}
                </div>
              </div>
              {/* Notice Date */}
              <div className="col-md-4">
                <div className="form-group">
                  <Label label="Notice Date" />
                  <Input
                    inputclasses="form-controlcustom"
                    type="date"
                    name="noticeDate"
                    value={resignationDetail.noticeDate}
                    readOnly
                  />
                  {errors.noticeDate && (
                    <div className="text-danger">
                      {errors.noticeDate}
                    </div>
                  )}
                </div>
              </div>

              {/* Resignation Date */}
              <div className="col-md-4">
                <div className="form-group">
                  <Label label="Resignation Date" />
                  <Input
                    inputclasses="form-controlcustom"
                    type="date"
                    name="resignationDate"
                    value={resignationDetail.resignationDate}
                    readOnly
                  />
                  {errors.resignationDate && (
                    <div className="text-danger">
                      {errors.resignationDate}
                    </div>
                  )}
                </div>
              </div>
              {/* Reason */}
              <div className="col-md-12">
                <div className="form-group">
                  <Label label="Reason" />
                  <Textarea
                    style={{ height: "80px", width: "100%" }}
                    name="reason"
                    value={resignationDetail.reason}
                    onChange={handleInputChange}
                    maxLength={255}
                  />
                  {errors.resignationReason && (
                    <div className="text-danger">
                      {errors.resignationReason}
                    </div>
                  )}
                </div>
              </div>
            </div>

            {/* Submit Button */}
            <div className="d-flex justify-content-end">
              <Button
                onClick={createResignation}
                variant="primary"
                type="submit"
                label="Submit"
              />
            </div>
          </form>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </>
  );
};

export default EmployeeCreateResignation;
