import { Table } from "UI_Components";
import React, { useEffect, useState } from "react";
import LeaveView from "./LeaveView";
import { AlertMessage, Button, CommonModal, CoreLoader } from "common/components";

import ActionLeave from "./ActionLeave";
import apiManager from "networking/baseAPIManager";
import { SearchFilter } from "Components/AttendaceReport";
import ConfirmationModal from "common/components/ConfirmationModal";
import { Pagination } from "rsuite";

function LeaveApproval({ approvalData, fetchapi }) {
  const [open, setOpen] = useState(false);
  const [view, setView] = useState(true);
  const [deleteModal, setDeleteModal] = useState(true);
  const [modalContent, setModalContent] = useState({});
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [dataPayload, setdataPayload] = useState({});
  const [searchString, setSearchString] = useState("");
  const [filteredApprovalData, setFilteredApprovalData] = useState(approvalData);
  const [confirm, setConfirm] = useState(false);
  const [activePage, setActivePage] = useState(1);
  const [displayedData, setDisplayedData] = useState([]);
  const itemsPerPage = 10;

  useEffect(() => {
    const start = (activePage - 1) * itemsPerPage;
    const end = start + itemsPerPage;
    const paginatedData = filteredApprovalData.slice(start, end).map((item, index) => ({
      ...item,
      sno: start + index + 1,
    }));
    setDisplayedData(paginatedData);
  }, [activePage, filteredApprovalData]);

  useEffect(() => {
    if (searchString) {
      const result = approvalData.filter((rowObj) => {
        return (
          rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.employee_name.toLowerCase().includes(searchString.toLowerCase())
        );
      });
      setFilteredApprovalData(result);
    } else {
      setFilteredApprovalData(approvalData);
    }
  }, [approvalData, searchString]);

  const handleApproveReject = (flag, rowData) => {
    if (flag === "approve") {
      setdataPayload({
        leaveid: rowData._id,
        status: "approved",
      });
    } else {
      setdataPayload({
        leaveid: rowData._id,
        status: "rejected",
      });
    }

    setOpen(true);
    setView(false);
    setDeleteModal(true);
  };
  const handleRejectconfirm = () => {
    apiManager
      .leaveStatus(dataPayload)
      .then((res) => {
        setLoading(true);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
      })
      .catch((err) => {
        setmsgType("error");
        setShowMSG(err?.data?.message || "failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    confirm === true && handleRejectconfirm();
    setConfirm(false);
  }, [confirm === true]);
  const customHtml = (data) => {
    return <Button onClick={() => handleItemClick(data)} label="View" sizeclass="" />;
  };

  const customHtml1 = (data) => {
    return (
      <div style={{ position: "relative" }}>
        <ActionLeave rowData={data} handleApproveReject={(flag, data) => handleApproveReject(flag, data)} />
      </div>
    );
  };

  const customHtml2 = (data) => (
    <div className="Actions-data-select">
      {customHtml(data)}
      {customHtml1(data)}
    </div>
  );

  const moment = require("moment");
  const dateHtml = (date) => moment(date).format("DD MMM YYYY");
  const columns = [
    { header: "S.No", field: "" },
    { header: "Employee Id", field: "employeeID" },
    { header: "Employee Name", field: "employee_name" },
    { header: "Leave Type", field: "type" },
    { header: "Start Date", field: "start_date", customHtml: (data) => dateHtml(data.start_date) },
    { header: "End Date", field: "end_date", customHtml: (data) => dateHtml(data.end_date) },
    { header: "Total Leave", field: "leave_days" },
    { header: "Actions", field: "actions", btnType: "primary", customHtml: customHtml2 },
  ];

  const handleItemClick = (data) => {
    setModalContent(data);
    setView(true);
    setOpen(true);
    setDeleteModal(false);
  };

  return (
    <div className="card mt-3">

<div class="card-top align-items-center">
  <div class="card-head-left">
    <div class="card-title">Leave Approval Request</div>
    </div>
    <div class="card-head-right">
         <SearchFilter
            data={searchString}
            setSearchString={setSearchString}
            placeholder={"Search Employee By Name/ID"}
          />
        <div class="Shortbytbtn">
          <span>Shortby :</span>
          <select name="">
            <option value="">IT</option>
            <option value="saab">Frontend</option>
            <option value="mercedes">UI &amp; UX</option>
            </select>
          </div>
        </div>
        </div>




      <div className="tableContainer">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

        <Table
          columns={columns}
          Data={displayedData}
          btn_name="view"
          LeaveTableHeight="LeaveTableHeight"
          secondary_btn_label="Action "
        />
      </div>
      <div className="pt-4 d-flex justify-content-end">
        <Pagination
          prev
          last
          next
          first
          size="sm"
          total={filteredApprovalData.length}
          limit={itemsPerPage}
          activePage={activePage}
          onChangePage={setActivePage}
        />
      </div>
      <CommonModal open={open} onClose={() => setOpen(false)} size={view ? "sm" : "xs"} title="Leave Details">
        {view ? <LeaveView Data={modalContent} setOpen={setOpen} /> : null}
        {deleteModal ? <ConfirmationModal setConfirm={setConfirm} setOpen={setOpen} /> : null}
      </CommonModal>

      <CoreLoader show={loading} disableLines />
    </div>
  );
}

export default LeaveApproval;
