import React from "react";

import ApexChart from "react-apexcharts";

const defaultOption = {
  chart: {
    type: "area",
    stacked: false,
    height: 400,
    zoom: {
      type: "x",
      enabled: true,
      autoScaleYaxis: true,
    },
    toolbar: {
      autoSelected: "zoom",
    },

  },
  dataLabels: {
    enabled: false,
  },
  markers: {
    size: 0,
  },
  title: {
    // text: 'Stock Price Movement',
    align: "left",
  },
  fill: {
    type: "gradient",
    gradient: {
      shadeIntensity: 1,
      inverseColors: false,
      opacityFrom: 0.5,
      opacityTo: 0,
      stops: [0, 90, 100],
    },
  },
  yaxis: {
    labels: {
      formatter: function (val) {
        return (val / 1000000).toFixed(0);
      },
    },
    title: {
      text: "Price",
    },
  },
  xaxis: {
    type: "datetime",
  },
  tooltip: {
    shared: false,
    y: {
      formatter: function (val) {
        return (val / 1000000).toFixed(0);
      },
    },
  },
  series: [],
  // Add your desired color for the area chart
  // colors: colorOptions
};

const Charts = ({ seriesData, option, type = "area", height = 270 }) => {
  return (
    <ApexChart type={type} options={option} series={option?.series} height={height} width="100%" className="w-full" />
  );
};

export default Charts;
