import React from "react";
import "../../assets/style/attendance/Emp-raw.css";


export const EmpRawData = ({ image, title, number }) => {
  return (
    <>
      <div className="col-sm-3">
      <div class="topcard clearfix">
        <figure>
        <figure> <img src={image} alt="" /> </figure>
        </figure>
        <div class="topcard-info">
          <h4>{number}</h4>
          <p>{title} </p>
          </div>
      </div>
      </div>
    </>
  );
};
