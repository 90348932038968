import { SearchFilter } from "Components/AttendaceReport";
import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CardWrapper, CommonModal } from "common/components";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "../../../assets/style/assetmanagement.css";



import useremployee from "../../../../src/assets/images/useremployee.png";
import LeaveEmployee from "../../../../src/assets/images/LeaveEmployee.png";
import EmployeeOnboarding from "../../../../src/assets/images/EmployeeOnboarding.png";
import ProbationEmployee from "../../../../src/assets/images/ProbationEmployee.png";


import BulkAssetModal from "common/components/BulkAssetModal";
import AddAssets from "./AddAssets";
import activeassets from "../../../../src/assets/images/activeassets.png";

export default function AssetManagement() {
  const [open, setOpen] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const tilesJson = [
    {
      title: "Number Of Assets",
      icon: useremployee,
      path: "/hrtools/assets-management/inventory",
    },
    {
      title: "Active Assets",
      icon: LeaveEmployee,
      path: "/hrtools/assets-management/inventory",
    },
    {
      title: "Assigned Asset",
      icon: EmployeeOnboarding,
      path: "/hrtools/assets-management/asset-assignment",
    },
    {
      title: "Unassigned Asset",
      icon: ProbationEmployee,
      path: "/hrtools/assets-management/pending-asset-verification",
    },
  ];
  const navigate = useNavigate();
  const renderTiles = () => {
    return tilesJson.map((tiles, index) => {
      return (
        <div className="col-sm-3" key={index}>
          <div className="topcard clearfix" onClick={() => navigate(tiles.path)}>
              <figure>
              <img src={tiles.icon} />
              </figure>
              <div className="topcard-info">
                <h4>00</h4>
              <p>{tiles.title}</p>
              </div>
          </div>
        </div>
      );
    });
  };
  return (
    <>
      <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
    
      <div className="container-fluid">
      <div className="row AssetsManagement-head">
         <div className="col-6">
         <SubHeader location={useLocation()} />
         </div>
         <div className="col-6">

         
          <div className="d-flex justify-content-end gap-3">
           <SearchFilter />
            <Button className="button AddAsset-btn" label="Add Asset" onClick={() => navigate("/hrtools/assets-management/add-asset")} />
            <Button label="Bulk Upload Asset" onClick={() => setOpen(true)} />
          </div>
        </div>
        </div>
        <div className="row topcard-row Assetstopcard">{renderTiles()}</div>

        <CommonModal open={open} onClose={() => setOpen(false)} size="md" title="Bulk Asset Upload">
          <BulkAssetModal setmsgType={setmsgType} setShowMSG={setShowMSG} setOpen={setOpen} />
        </CommonModal>
      </div>
    
    </>
  );
}
