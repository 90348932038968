import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CoreLoader, Dropdown, Input, Label } from "common/components";
import { useLocation, useNavigate } from "react-router-dom";
import apiManager from "networking/baseAPIManager";
import { useEffect, useState } from "react";
import { useFormik } from "formik";
import { AssatSchema } from "utils/formValidationUtils";

const AddAssets = () => {
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [assetNameTypes, setAssetNameTypes] = useState([]);
  const [assetStatusTypes, setAssetStatusTypes] = useState([]);
  const [manufacturerTypes, setManufacturerTypes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    Promise.all([getAssetNameTypes(), getAssetStatusTypes(), getManufacturerTypes()])
      .then((results) => {
        const assetNameTypesResponse = results[0];
        const assetStatusTypesResponse = results[1];
        const manufacturerTypesResponse = results[2];

        setAssetNameTypes(assetNameTypesResponse?.data?.data);
        setAssetStatusTypes(assetStatusTypesResponse?.data?.data);
        setManufacturerTypes(manufacturerTypesResponse?.data?.data);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, []);

  const getAssetNameTypes = () => {
    return apiManager.getDropdownTypes("assetName");
  };

  const getAssetStatusTypes = () => {
    return apiManager.getDropdownTypes("assetStatus");
  };

  const getManufacturerTypes = () => {
    return apiManager.getDropdownTypes("manufacturer");
  };

  const initialValues = {
    assetName: "",
    purchaseDate: "",
    manufacturer: "",
    serialNumber: "",
    assetValue: "",
    documentUpload: "",
    assetId: "",
    purchaseFrom: "",
    model: "",
    supplier: "",
    warranty: "",
    description: "",
    status: "",
  };

  const { handleChange, handleBlur, handleSubmit, values, errors, touched, setFieldValue } = useFormik({
    initialValues: initialValues,
    validationSchema: AssatSchema,
    onSubmit: (values, { resetForm }) => {
      let formdata = new FormData();
      formdata.append("assetname", values.assetName);
      formdata.append("assetId", values.assetId);
      formdata.append("purchase_date", values.purchaseDate);
      formdata.append("purchase_from", values.purchaseFrom);
      formdata.append("manufacturer", values.manufacturer);
      formdata.append("model", values.model);
      formdata.append("serial_number", values.serialNumber);
      formdata.append("supplier", values.supplier);
      formdata.append("warranty", values.warranty);
      formdata.append("value", values.assetValue);
      formdata.append("status", values.status);
      formdata.append("image", values.documentUpload);
      formdata.append("description", values.description);

      setLoading(true);
      apiManager
        .AddAsset(formdata)
        .then((res) => {
          setLoading(false);
          setmsgType("success");
          setShowMSG(res?.data?.message || "asset added successfully");
          resetForm();
          const fileInput = document.querySelector('input[type="file"]');
          if (fileInput) {
            fileInput.value = "";
          }
        })
        .catch((err) => {
          setLoading(false);
          setmsgType("error");
          setShowMSG(err?.response?.data?.message || "failed to add asset");
        });
    },
  });
  return (
    <>


      <div className="container-fluid">
        <SubHeader location={useLocation()} />
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />

        <div className="form-main card mt-3">
          <div className="card-title mb-2">
            Add Asset
          </div>
          <form onSubmit={handleSubmit}>

            <div className="form-container">
              <div className="row">

                <div className="col-sm-4">
                  <div className="form-group">
                    <Label label="Asset Name" required />

                    <Dropdown
                      name="assetName"
                      value={values.assetName}
                      options={assetNameTypes}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.assetName}
                      touched={touched.assetName}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="form-group">
                    <Label label="Purchase Date" required />
                    <Input
                      type="date"
                      name="purchaseDate"
                      value={values.purchaseDate}
                      onBlur={handleBlur}
                      onChange={handleChange}
                      error={errors.purchaseDate}
                      touched={touched.purchaseDate}
                      min="1950-01-01"
                      max="2999-12-31"
                    />
                  </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Manufacturer " required />
                  <Dropdown
                    name="manufacturer"
                    value={values.manufacturer}
                    options={manufacturerTypes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.manufacturer}
                    touched={touched.manufacturer}
                    maxlength={50}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Serial Number" required />
                  <Input
                    name="serialNumber"
                    value={values.serialNumber}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.serialNumber}
                    touched={touched.serialNumber}
                    maxlength={15}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Asset Value" required />
                  <Input
                    name="assetValue"
                    value={values.assetValue}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.assetValue}
                    touched={touched.assetValue}
                    maxlength={10}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Status" required />
                  <Dropdown
                    name="status"
                    value={values.status}
                    options={assetStatusTypes}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.status}
                    touched={touched.status}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Asset Id" required />
                  <Input
                    name="assetId"
                    value={values.assetId}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.assetId}
                    touched={touched.assetId}
                    maxlength={20}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Purchase From" required />
                  <Input
                    name="purchaseFrom"
                    type="text"
                    value={values.purchaseFrom}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.purchaseFrom}
                    touched={touched.purchaseFrom}
                    minlength={3}
                    maxlength={35}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Model" required />
                  <Input
                    name="model"
                    value={values.model}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.model}
                    touched={touched.model}
                    maxlength={20}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Supplier" required />
                  <Dropdown
                    name="supplier"
                    options={manufacturerTypes}
                    value={values.supplier}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.supplier}
                    touched={touched.supplier}
                    maxlength={50}
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Warranty" required />
                  <Input
                    name="warranty"
                    type="date"
                    value={values.warranty}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.warranty}
                    touched={touched.warranty}
                    min="1950-01-01"
                    max="2999-12-31"
                  />
                </div>
                </div>
                <div className="col-sm-4">
                <div className="form-group">
                  <Label label="Document Image" />
                  <Input
                    type="file"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      setFieldValue("documentUpload", e.target.files[0]);
                    }}
                    // error={errors.documentUpload}
                    // touched={touched.documentUpload}
                    isValid={touched.documentUpload && !errors.documentUpload}
                    touched={touched.documentUpload}
                    error={errors.documentUpload}
                  />
                </div>
                </div>
                <div className="col-sm-12">
                <div className="form-group">
                  <Label label="Description" />
                  <textarea
                  className="form-control"
                    name="description"
                    value={values.description}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    error={errors.description}
                    touched={touched.description}
                    maxlength={150}
                  />
                </div>
                </div>

              </div>
             
          

            </div>

            <div className=" col-lg-12 d-flex gap-3 justify-content-end py-2 ">
              <Button className="button" variant="primary" type="submit" label="Submit" />

              <Button
                className=" btn btn-primary can-btn"
                variant="primary"
                label="Close"
                onClick={() => navigate("/hrtools/assets-management")}
              />
            </div>

      </form>
    </div>
      </div>
  <CoreLoader show={loading} disableLines />
    </>
  );
};

export default AddAssets;
