import { SearchFilter } from "Components/AttendaceReport";
import SubHeader from "UI_Components/SubHeader";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import "./../../../assets/style/EmployeeProfile/EmployeeProfile.css";
import { Dropdown } from "common/components";
import ProfileCard from "Components/EmployeeProfile/ProfileCard";
import apiManager from "networking/baseAPIManager";
import { useSelector, useDispatch } from "react-redux";

///profile detais pages import //
import profileimg from "./../../../assets/images/profileimg.png";
import { PiBagSimple } from "react-icons/pi";
import { IoMailOutline } from "react-icons/io5";
import { IoDownloadSharp } from "react-icons/io5";




const EmployeeProfile = () => {
  const refresh = useSelector((state) => state.data.refresh);
  const [loading, setLoading] = useState(false);
  const [searchString, setSearchString] = useState("");
  const [employeeOnleave, setEmployeeOnleave] = useState([]);
  const [employeeDetail, setEmployeeDetail] = useState([]);
  const [filteredApprovalData, setFilteredApprovalData] = useState([]);
  const [getDesignation, setDesignation] = useState([]);
  const [getDepartment, setDepartment] = useState([]);
  const [selectedDesignation, setSelectedDesignation] = useState("");
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const location = useLocation();
  const flagFromAttendance = location.state;

  let designation = selectedDepartment == "" ? selectedDepartment : selectedDepartment?.toLocaleLowerCase();

  useEffect(() => {
    fetchEmployeesOnLeave();
    if (designation) {
      getDesignationList();
    }
    getDepartmentList();
  }, [designation]);

  const fetchEmployeesOnLeave = () => {
    setLoading(true);
  };
  const getDepartmentList = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes("department")
      .then((response) => {
        setLoading(false);
        setDepartment(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  const getDesignationList = () => {
    setLoading(true);
    apiManager
      .getDesignation(designation)
      .then((response) => {
        setLoading(false);

        setDesignation(response?.data?.data);
      })
      .catch((err) => {
        setLoading(false);
        console.error(err);
      });
  };

  useEffect(() => {
    setLoading(true);
    let status = flagFromAttendance === "leave" ? "?status=onleave" : "?status=active";
    apiManager
      .activeEmployeeList(status)
      .then((response) => {
        setLoading(false);
        if (status === "?status=onleave") {
          setEmployeeOnleave(response.data);
        } else {
          setEmployeeDetail(response.data);
        }
      })
      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  }, [flagFromAttendance, refresh]);

  useEffect(() => {
    if (selectedDepartment !== "ALL DEPARTMENT") {
      const filteredEmployees = employeeDetail.filter((employee) => employee?.department === selectedDepartment);
      setFilteredApprovalData(filteredEmployees);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [selectedDepartment]);

  useEffect(() => {
    if (selectedDesignation !== "All") {
      const filteredEmployees = employeeDetail.filter((employee) => employee?.designation === selectedDesignation);
      setFilteredApprovalData(filteredEmployees);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [selectedDesignation]);

  useEffect(() => {
    if (searchString) {
      const result = filteredApprovalData.filter(
        (rowObj) =>
          rowObj.employeeID.toLowerCase().includes(searchString.toLowerCase()) ||
          rowObj.firstname.toLowerCase().includes(searchString.toLowerCase())
      );
      setFilteredApprovalData(result);
    } else {
      setFilteredApprovalData(employeeDetail);
    }
  }, [employeeDetail, searchString]);

  return (
    <>
      <div className="container-fluid ">
        <SubHeader location={location} />
        <div className="mt-3">
          <div className="card-top align-items-center">
            <div className="card-head-left">
              <div className="card-title">All Employees</div>
            </div>
            <div className="card-head-right">
              <SearchFilter
                data={searchString}
                setSearchString={setSearchString}
                placeholder="Search Employee By Name/ID"
              />

              <div className="Shortbytbtn">
                <span>Short by :</span>
                <Dropdown
                  value={selectedDepartment}
                  options={getDepartment}
                  onChange={(e) => setSelectedDepartment(e.target.value)}
                  placeholder="Select Department"
                />
              </div>
              <div className="Shortbytbtn">
                <span>Short by :</span>
                <Dropdown
                  value={selectedDesignation}
                  options={getDesignation}
                  onChange={(e) => setSelectedDesignation(e.target.value)}
                  placeholder="Select Designation"
                />
              </div>

            </div>
          </div>
        </div>
        <ProfileCard
          flagFromAttendance={flagFromAttendance}
          filteredApprovalData={filteredApprovalData}
          employeeOnleave={employeeOnleave}
        />
      </div>

      {/* <div className="container-fluid">
        <SubHeader location={location} />

        <div className="mt-4 mb-4">
          <div className="card-title">Employees Details</div>
        </div>

        <div className="row Emp-Det-Row">
          <div className="col-4">
            <div className="Emp-Det-left">
              <div className="card">
                <div className="Emp-top-info clearfix">
                  <div className="Emp-profile-img">
                    <img src={profileimg} alt="" />
                  </div>
                  <div className="Emp-profile-text">
                    <h5>Disha Tailang</h5>
                    <p><PiBagSimple /> UI & UX Designer </p>
                    <p className="m-0"><IoMailOutline /> disha03@singhsoft.com</p>
                  </div>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-title">Personal Details</div>
                <div className="Emp-Det-List">
                  <ul>
                    <li>
                      <span>Documental DOB</span>
                      <p>26 Dec 1994</p>
                    </li>
                    <li>
                      <span>Original DOB</span>
                      <p>26 Dec 1994</p>
                    </li>
                    <li>
                      <span>Gender</span>
                      <p>Femail</p>
                    </li>
                    <li>
                      <span>Marital Status</span>
                      <p>Married</p>
                    </li>
                    <li>
                      <span>Mobile No.</span>
                      <p>8740865095</p>
                    </li>
                    <li>
                      <span>Emergency Contact No.</span>
                      <p>8740865095</p>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="card mt-3">
                <div className="card-title">Professional Details</div>
                <div className="Emp-Det-List">
                  <ul>
                    <li>
                      <span>Employee ID</span>
                      <p>Stek0021</p>
                    </li>
                    <li>
                      <span>Mail Id</span>
                      <p>disha03@singhsoft.com</p>
                    </li>
                    <li>
                      <span>Department</span>
                      <p>IT Department </p>
                    </li>
                    <li>
                      <span>Designation</span>
                      <p>UI & UX Designer </p>
                    </li>
                    <li>
                      <span>Joining Date</span>
                      <p>03  Oct 2024 </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="col-8">
            <div className="Emp-Det-left">


              <div className="accordion" id="EmployeesAccordionRobots">

                <div className="accordion-item">
                  <div id="regularHeadingFirst" className="accordion-header">
                    <button className="accordion-button" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseFirst" aria-expanded="true" aria-controls="regularCollapseFirst">
                      Education
                    </button>
                  </div>
                  <div id="regularCollapseFirst" className="accordion-collapse collapse show" aria-labelledby="regularHeadingFirst" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      <div className="row">
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Secondary Passing </label>
                            <div className="Emp-form-info">
                              <p> 85% </p>
                              <span>
                                <IoDownloadSharp />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Senior Passing </label>
                            <div className="Emp-form-info">
                              <p> 85% </p>
                              <span>
                                <IoDownloadSharp />
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-4">
                          <div className="form-group">
                            <label class="lables">Bachelor’s Passing  </label>
                            <div className="Emp-form-info">
                              <p> 85% </p>
                              <span>
                                <IoDownloadSharp />
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingSecond">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseSecond" aria-expanded="false" aria-controls="regularCollapseSecond">
                      Experience
                    </button>
                  </div>
                  <div id="regularCollapseSecond" className="accordion-collapse collapse" aria-labelledby="regularHeadingSecond" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      Experience data
                    </div>
                  </div>
                </div>

                <div className="accordion-item">
                  <div className="accordion-header" id="regularHeadingThrid">
                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#regularCollapseThrid" aria-expanded="false" aria-controls="regularCollapseThrid">
                      Bank Details
                    </button>
                  </div>
                  <div id="regularCollapseThrid" className="accordion-collapse collapse" aria-labelledby="regularHeadingThrid" data-bs-parent="#EmployeesAccordionRobots">
                    <div className="accordion-body">
                      Bank Details data
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div> */}

    </>
  );
};

export default EmployeeProfile;
