
import React, { useState, useEffect } from "react";
import { Button, Input, Dropdown, AlertMessage, CoreLoader, Textarea, Label, CommonModal } from "common/components";
import apiManager from "networking/baseAPIManager";
// Import the CommonModal

function CreateRequest({ fetchapi, openModal, closeModal }) {
  const [leaveTypeOptions, setLeaveTypeOptions] = useState([]);
  const [selectType, setSelectType] = useState("");
  const [selectEndDate, setselectEndDate] = useState("");
  const [selectStartDate, setSelectStartDate] = useState("");
  const [Session, setSession] = useState("");
  const [selectReason, setReason] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [showMSG, setShowMSG] = useState(false);
  const [msgType, setmsgType] = useState("info");
  const [showInput, setShowInput] = useState(false);
  const [errors, setErrors] = useState({});

  const leaveTypeOption = [
    { key: "session1", value: "Session 1" },
    { key: "session2", value: "Session 2" },
  ];

  const datesMatch = selectStartDate === selectEndDate;

  useEffect(() => {
    if (!datesMatch) {
      setShowInput(false);
    }
  }, [datesMatch]);

  const leaveTypes = "leaveTypes";
  const fetchLeaveTypeOptions = () => {
    setLoading(true);
    apiManager
      .getDropdownTypes(leaveTypes)
      .then((response) => {
        setLeaveTypeOptions(response.data.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching leave type options:", error);
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchLeaveTypeOptions(leaveTypes);
  }, []);

  const validateForm = () => {
    const newErrors = {};

    if (!selectType) newErrors.selectType = "Leave Type is required";
    if (!selectStartDate) newErrors.selectStartDate = "Start Date is required";
    if (!selectEndDate) newErrors.selectEndDate = "End Date is required";

    if (selectStartDate && selectEndDate) {
      if (new Date(selectStartDate) > new Date(selectEndDate)) {
        newErrors.dateRange = "End Date cannot be before Start Date";
      }
    }

    if (showInput && !Session) newErrors.Session = "Session is required";

    if (selectedFile) {
      const allowedTypes = ["image/jpeg", "image/png", "application/pdf"];
      if (!allowedTypes.includes(selectedFile.type)) {
        newErrors.selectedFile = "Only JPG,PNG,or PDF files are allowed";
      } else if (selectedFile.size > 2 * 1024 * 1024) {
        newErrors.selectedFile = "File size should not exceed 2MB";
      }
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleLeaveSubmit = (e) => {
    e.preventDefault();
    if (!validateForm()) return;

    let formdata = new FormData();
    formdata.append("type", selectType);
    formdata.append("start_date", selectStartDate);
    formdata.append("end_date", selectEndDate);
    formdata.append("session", Session);
    formdata.append("reason", selectReason);
    formdata.append("document", selectedFile);

    setLoading(true);
    apiManager
      .createLeave(formdata)
      .then((res) => {
        setLoading(false);
        fetchapi && fetchapi();
        setmsgType("success");
        setShowMSG(res?.data?.message || "Details updated successfully");
        resetFormFields();
        closeModal(); // Close the modal after successful submission
      })
      .catch((err) => {
        setLoading(false);
        setmsgType("error");
        setShowMSG(err?.response?.data?.message || "Failed to update");
        console.log(err, "Leave Request error");
      });
  };

  const resetFormFields = () => {
    setSelectType("");
    setSelectStartDate("");
    setselectEndDate("");
    setSession("");
    setReason("");
    setSelectedFile(null);
    const fileInput = document.getElementById("attachment");
    if (fileInput) {
      fileInput.value = "";
    }
  };

  const handleDeleteClick = () => {
    setSelectType("");
    setSelectStartDate("");
    setselectEndDate("");
    setSession("");
    setReason("");
    setSelectedFile(null);
  };

  const getLastMonthFormatted = () => {
    const currentDate = new Date();
    const lastMonth = new Date(currentDate);
    lastMonth.setMonth(currentDate.getMonth() - 1);
    const formattedDate = lastMonth.toISOString().split("T")[0];
    return formattedDate;
  };

  return (
    <CommonModal open={openModal} onClose={closeModal} title="Create Leave Request">
      <div className="col-lg-12 p-3">
        <AlertMessage show={showMSG} setShow={setShowMSG} type={msgType} />
            <div className="form-container container">
      
                <form className="leave-request row">
                  <div className="col-12">
                    <div className="form-group">
                    <Label label="Leave Type" required />
                      <Dropdown
                        value={selectType}
                        onChange={(e) => setSelectType(e.target.value)}
                        options={leaveTypeOptions}
                        placeholder="Select Leave Types"
                        sidelabel
                      />
                      {errors.selectType && <div className="text-danger ">{errors.selectType}</div>}
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="form-group">
                    <Label label="Start Date" required />
                      <Input
                        inputclasses="form-controlcustom"
                        type="date"
                        name="startDate"
                        value={selectStartDate}
                        onChange={(e) => setSelectStartDate(e.target.value)}
                        condition={getLastMonthFormatted()}
                      />
                      {errors.selectStartDate && <span className="text-danger ">{errors.selectStartDate}</span>}
                    </div>
                  </div>
                  <div className="col-6">
                  <div className="form-group">
                    <Label label="End Date" required />
                      <Input
                        inputclasses="form-controlcustom"
                        type="date"
                        name="endDate"
                        value={selectEndDate}
                        onChange={(e) => setselectEndDate(e.target.value)}
                        min={getLastMonthFormatted()}
                        condition={selectStartDate}
                      />
                      {errors.selectEndDate && <span className="text-danger ">{errors.selectEndDate}</span>}
                      {errors.dateRange && <div className="text-danger ">{errors.dateRange}</div>}
                    </div>
                  </div>
                  <div className="col-12">
                   <div className="checkbox-LABLE">
                    <input
                      type="checkbox"
                      inputclasses="form-check-input"
                       checked={showInput}
                      id='ShowInput'
                      onChange={(e) => setShowInput(e.target.checked)}
                      disabled={!datesMatch}
                    />
                     <label className="lables" for='ShowInput'>Half-day</label>
                   </div>

                    {showInput && (
                         <div className="form-group">
                      <Dropdown
                        id="dropdown"
                        name="session"
                        value={Session}
                        onChange={(e) => setSession(e.target.value)}
                        options={leaveTypeOption}
                        placeholder="Select Session"
                        datesMatch={selectStartDate === selectEndDate}
                      />
                       </div>
                    )}
                    {errors.Session && <span className="text-danger ">{errors.Session}</span>}
                   

                  </div>
                       <div className="col-12">
                       <div className="form-group">
                    <Label label="Reason" required />
                      <Textarea
                        name="reason"
                        value={selectReason}
                        onChange={(e) => setReason(e.target.value)}
                        maxLength={200}
                      />
                      {errors.selectReason && (
                        <div className="text-danger  d-flex justify-content-center">{errors.selectReason}</div>
                      )}
                    </div>
                  </div>
                       <div className="col-12">
                       <div className="form-group">
                    <Label label="Attachment" />
                      <Input
                        inputclasses="form-controlcustom"
                        type="file"
                        id="attachment"
                        name="attachment"
                        onChange={(e) => setSelectedFile(e.target.files[0])}
                        sidelabel
                      />
                      {errors.selectedFile && <div className="text-danger ">{errors.selectedFile}</div>}
                    </div>
                  </div>
                </form>
              
            </div>
          <div className="d-flex gap-3 justify-content-end pt-2">
            <button onClick={handleLeaveSubmit} variant="primary" className="button" type="submit" label="Submit">Submit</button>
            <Button onClick={handleDeleteClick} variant="primary" type="button" label="Cancel" />
          </div>
    
        
      </div>
      <CoreLoader show={loading} disableLines />
    </CommonModal>
  );
}

export default CreateRequest;
