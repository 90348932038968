import { SubHeader } from "UI_Components";
import { AlertMessage, Button, CoreLoader, Input, Label } from "common/components";
import apiManager from "networking/baseAPIManager";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

export const AddHolidayListCalendar = () => {
  const [loading, setLoading] = useState(false);
  const [holidays, setHolidays] = useState([]);
  const [showMSG, setShowMSG] = useState("");
  const [msgType, setMsgType] = useState("info");

  const { id } = useParams();
  const navigate = useNavigate();
  const fetchHolidays = () => {
    setLoading(true);
    let params = {
      id: id,
    };

    apiManager
      .holidaypending(params)
      .then((res) => {
        setHolidays(res.data.status);
      })
      .catch((error) => {
        setMsgType("error");
        setShowMSG(error?.response?.data?.message || "Failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    fetchHolidays();
  }, [id]);

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    const updatedHolidays = [...holidays];
    if (name === "holiday_name") {
      const regex = /^[A-Za-z ]*$/;
      if (!regex.test(value)) {
        return;
      }
    }
    updatedHolidays[index][name] = value;
    setHolidays(updatedHolidays);
  };

  const handleApproveReject = async (action = false) => {
    setLoading(true);
    let params = {
      id: id,
      holiday_status: action ? "approved" : "rejected",
    };
    apiManager
      .holidayapprovied(params)
      .then((response) => {
        setMsgType("success");
        setShowMSG(response?.data?.message || "Updated successfully");
        navigate("/hrtools/attendance/holiday-calendar");
      })
      .catch((error) => {
        setMsgType("error");
        setShowMSG(error?.response?.data?.message || "Failed to update");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <>
      <div className="Container">
        <AlertMessage show={showMSG} type={msgType} />
        <SubHeader location={useLocation()} />
        <div className="d-flex justify-content-between">
          <div className="row p-3 g-3 mb-3 border rounded m-5 main_table">
            <div className="col-md-6 p-3 mb-3 border rounded">
              <Label label="Holiday Name" parentclasses="col-lg-6 col-xxl-4" />
              <Input
                type="text"
                name="holiday_name"
                inputclasses="form-controlcustom w-100"
                parentclasses="col-xl-12 "
                value={holidays.holiday_name}
                onChange={(event) => handleInputChange(event)}
              />
              <Label label="Date" parentclasses="col-lg-6 col-xxl-4 mt-4" />
              <Input
                type="date"
                name="date"
                inputclasses="form-controlcustom w-100"
                parentclasses=" col-xl-12 mt-4"
                value={holidays.date}
                onChange={(event) => handleInputChange(event)}
              />
            </div>
            <div className="d-flex gap-3 justify-content-end py-2  col-md-12">
              <Button type="button" label="Approve" onClick={() => handleApproveReject(true)} />
              <Button type="button" label="Reject" onClick={() => handleApproveReject(false)} />
            </div>
          </div>
        </div>
      </div>
      <CoreLoader show={loading} disableLines />
    </>
  );
};
