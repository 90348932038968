import React, { useEffect, useState } from "react";
import SCard from "../../UI_Components/SCard";
import SubHeader from "../../UI_Components/SubHeader";
import { useLocation, useNavigate } from "react-router-dom";
import useremployee from "../../../src/assets/images/useremployee.png";
import LeaveEmployee from "../../../src/assets/images/LeaveEmployee.png";
import EmployeeOnboarding from "../../../src/assets/images/EmployeeOnboarding.png";
import ProbationEmployee from "../../../src/assets/images/ProbationEmployee.png";
import profileimg from "../../../src/assets/images/profileimg.png";
import Charts from "common/components/Chart";
import HrtoolDashoardTable from "Components/GraphData/HrToolDashboard/HrtoolDashoardTable";
import apiManager from "networking/baseAPIManager";
import "../../assets/style/Responsive.css";
import ReactApexChart from "react-apexcharts";
import { LiaBirthdayCakeSolid } from "react-icons/lia";
import CalendarApp from "common/components/CalendarApp";
import NoDataFound from "common/components/NoDataFound";
import { IoBagOutline } from "react-icons/io5";
import { formatDateCharcter, formateDate } from "utils/dateFunctions";

const Hrtools = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  let baseURL = process.env.REACT_APP_BASE_URL;
  const [yearlGraphData, setYearlGraphData] = useState();
  const [deparmentData, setDepartmentData] = useState([]);
  const [InformationData, setInformationData] = useState();
  const [circularBarData, setCircularBarData] = useState();
  const [employeeBarData, setEmployeeBarData] = useState(0);
  const [assetsBarData, setAssetsBarDat] = useState();
  const [totalassets, setTotalAssets] = useState({});
  const [aniversary, setaniversary] = useState([])
  const [dobemployee, setdobEmployee] = useState({
    dob_day: "",
    dob_month: ""
  })
  const [selectedOption, setSelectedOption] = useState({
    day: '',
    weekly: '',
    monthly: '',
    yearly: ''
  });
  const [totalattendance, settotalAttendance] = useState({
    totalEmployeeCount: 0,
    TotalEmployeePresent: 0,
    TotalEmployeeAbsent: 0,
    totalEmployeeLeaveToday: 0,
    TotalEmployeeHalfDay: 0
  })
  const keyData = [{ Key: "monthly", value: 30 }, { Key: "yearly", value: 365 }];
  let totalAssetsCount = totalassets?.active + totalassets?.assigned + totalassets?.inactive + totalassets?.repair + totalassets?.unassigned;
  const navigateToDepartment = (departmentName) => {
    navigate(departmentName);
  };
  const areadataUpdate = yearlGraphData?.series?.reduce(((key, ele) => {
    const { name, data } = ele
    if (name === "Onboarding") {
      key[name] = { name, data: [2, ...data] }
    } else {
      key[name] = { name, data: [11, ...data] }
    }
    return key
  }), {})
  const fetchTitleData = () => {
    setLoading(true);

    apiManager
      .employeecount()
      .then((response) => {
        setLoading(false);
        settotalAttendance((prev) => ({
          ...prev,
          totalEmployeeCount: response.data.totalEmployeeCount,
          TotalEmployeePresent: response.data?.TotalEmployeePresent,
          TotalEmployeeAbsent: response.data?.TotalEmployeeAbsent,
          totalEmployeeLeaveToday: response.data?.totalEmployeeLeaveToday,
          TotalEmployeeHalfDay: response.data?.TotalEmployeeHalfDay
        }))
      })

      .catch((error) => {
        setLoading(false);
        console.error(error);
      });
  };

  const tools = [
    {
      cardTitle: "Total Employees",
      cardContent: `${InformationData?.totalEmployeeCount || 0}`,
      image: useremployee,
    },
    {
      cardTitle: "Onboarding",
      cardContent: `${InformationData?.totalnewOnboarding || 0}`,
      image: LeaveEmployee,
    },
    {
      cardTitle: "Off-Boarding",
      image: EmployeeOnboarding,
      cardContent: `${InformationData?.totaloffOnboarding || 0}`,
    },
    {
      cardTitle: "Probation Employee",
      image: ProbationEmployee,
      cardContent: `${InformationData?.totaloffOnboarding || 0}`,
    },
  ];
  const attendeceData = {
    options: {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                showAlways: true,
                show: true
              }
            }
          }
        }

      },
    },
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          setTimeout(() => {
            const selectedDepartment = config.dataPointIndex;
            if (selectedDepartment !== undefined && selectedDepartment !== null) {
              // Safe navigation or action
              navigateToDepartment('/hrtools/attendance-dashboard');
            } else {
              console.error("No valid selection or departments available.");
            }
          }, 1);
        },
      },
    },
    title: {
      text: ""
    },
    series: [totalattendance?.TotalEmployeePresent, totalattendance?.TotalEmployeeAbsent, totalattendance?.TotalEmployeeHalfDay, totalattendance?.totalEmployeeLeaveToday],
    legend: {
      position: 'bottom',
      verticalAlign: 'bottom',
    },
    // plotOptions: {
    //   pie: {
    //     donut: {
    //       labels: {
    //         show: true,
    //         total: {
    //           showAlways: true,
    //           show: true,
    //           label: 'Total', // Text above the value
    //           fontSize: '16px', // General font size
    //           style: {
    //             label: {
    //               fontSize: '18px', // Font size for the label
    //               fontWeight: 'bold', // Font weight for the label
    //               color: '#333', // Label color
    //             },
    //             value: {
    //               fontSize: '24px', // Font size for the value
    //               fontWeight: '600', // Font weight for the value
    //               color: '#000', // Value color
    //             },
    //           },
    //         },
    //       },
    //     },
    //   },
    // },

    labels: ['Present', 'Absent', 'half day', 'Leave'],
    colors: ['#18BDC3', '#F26532', '#9A9C9C', '#FDB731'],
  };

  const donutoption = {
    series: [{
      data: deparmentData?.series,
    }],
    options: {
      chart: {
        type: 'bar',
        height: 350,
        toolbar: {
          show: false, // Disable the top menu
        },
      },
      title: {
        text: "",
      },
      labels: deparmentData?.departments,

      plotOptions: {
        bar: {
          borderRadius: 4,
          borderRadiusApplication: 'end',
          horizontal: true,
          distributed: true,
        },
      },
      dataLabels: {
        enabled: false,
      },
      xaxis: {
        categories: deparmentData?.departments,
      },
      colors: [
        "#F26532",
        "#18BDC3",
        "#FDB731",
        "#7A9B9C",
        "#F2CDC0",
        "#98948D",
        "#2980B9",
        "#8E44AD",
      ],
      legend: {
        show: false,
      },
    },
  };

  const areaChartData = {
    series: yearlGraphData?.series,
    // series: [areadataUpdate?.Onboarding, areadataUpdate?.OffBoarding],
    chart: {
      toolbar: {
        show: false,
      }
    },
    xaxis: {
      categories: ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
    },
    yaxis: {
      min: 0,
      max: 12,
      tickAmount: 6,
      labels: {
        formatter: (val) => (val < 10 ? `0${val}` : `${val}`),
      },
    },
    tooltip: {
      y: {
        formatter: (val) => val,
      },
    },
    colors: ["#18BDC3", "#F26532"],
  };



  const assetsData = {
    series: [totalassets?.active, totalassets?.assigned, totalassets?.unassigned, totalassets?.repair, totalassets?.inactive],
    options: {
      dataLabels: {
        enabled: false,
      },

    },
    chart: {
      toolbar: {
        show: false,
      },
      events: {
        dataPointSelection: (event, chartContext, config) => {
          setTimeout(() => {
            const selectedDepartment = config.dataPointIndex;
            if (selectedDepartment !== undefined && selectedDepartment !== null) {
              // Safe navigation or action
              navigateToDepartment('/hrtools/assets-management');
            } else {
              console.error("No valid selection or departments available.");
            }
          }, 1);
        },
      },
    },
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
            }
          }
        }
      }
    },
    labels: ["Active Assets", "Assigned Asset", "Unassigned Asset", "Repair", "Inactive"],
    legend: {
      show: false,
    },
  };


  const calculatePercentages = (data) => {
    const total = Object.values(data).reduce((sum, count) => sum + count, 0);
    if (total === 0) {
      return Object.keys(data).reduce((percentages, key) => {
        percentages[key] = "0";
        return percentages;
      }, {});
    }
    return Object.keys(data).reduce((percentages, key) => {
      percentages[key] = ((data[key] / total) * 100).toFixed(0);
      return percentages;
    }, {});
  };

  useEffect(() => {
    fetchTitleData();
    hrInformationData();
    departmentDatafetch();
    hrdDashboardYearlyData();
    hrdDashboardCircularData();
    hrEmployeeData();
    hrAssetsData();
    employeeBirthday()
    employeeBirthByMonth()
    TotalAssetsData()
    employeesAniversay()
  }, []);
  useEffect(() => {
    FilterDepartment()
  }, [selectedOption])
  const hrInformationData = () => {
    setLoading(true);
    try {
      apiManager
        .HrDashboardEmData()
        .then((response) => {
          setLoading(false);
          setInformationData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });

    } catch (error) {

    }
  };

  const departmentDatafetch = () => {
    setLoading(true);
    try {
      apiManager
        .HrDepartmentCount()
        .then((response) => {
          setLoading(false);
          setDepartmentData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });

    } catch (error) {

    }
  };

  const FilterDepartment = () => {
    setLoading(true);
    try {

      apiManager
        .HrDashboardYearlyWiseData({ month: selectedOption.monthly, year: selectedOption.yearly })
        .then((response) => {
          setLoading(false);
          setDepartmentData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {

    }
  };
  const hrdDashboardYearlyData = () => {
    setLoading(true);
    try {
      apiManager
        .HrDashboardYearly()
        .then((response) => {
          setLoading(false);
          setYearlGraphData(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });

    } catch (error) {

    }
  };
  const hrdDashboardCircularData = () => {
    setCircularBarData({ Completed: 0, InProcess: 0, Pending: 0 });
    setLoading(true);
    try {
      apiManager
        .HrOnboardingStatus()
        .then((response) => {
          setLoading(false);
          setCircularBarData(calculatePercentages(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });

    } catch (error) {

    }
  };

  const hrEmployeeData = () => {
    setLoading(true);
    try {

      apiManager
        .HrEmployeeStatus()
        .then((response) => {
          setLoading(false);
          setEmployeeBarData(calculatePercentages(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };

  const hrAssetsData = () => {
    setLoading(true);
    try {

      apiManager
        .HrDashboardAsset()
        .then((response) => {
          setLoading(false);
          setAssetsBarDat(calculatePercentages(response.data));
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {
      console.error(error);
    }
  };
  const TotalAssetsData = () => {
    setLoading(true);
    try {

      apiManager
        .TotalDashboardAsset()
        .then((response) => {
          setLoading(false);
          setTotalAssets(response.data);
        })
        .catch((error) => {
          setLoading(false);
          console.error(error);
        });
    } catch (error) {

    }
  };
  const employeeBirthByMonth = () => {
    setLoading(true);
    try {
      apiManager.EmployeeBirthmonth().then((response) => {
        setLoading(false)
        setdobEmployee((prev) => ({
          ...prev,
          dob_month: response.data
        }))
      }).catch()

    } catch (error) {
      console.error(error);
    }
  }
  const employeeBirthday = () => {
    setLoading(true);
    try {
      apiManager.EmployeeBirthday().then((response) => {
        setLoading(false)
        setdobEmployee((prev) => ({
          ...prev,
          dob_day: response.data
        }))
      }).catch()

    } catch (error) {
      console.error(error);
    }
  }
  const employeesAniversay = () => {
    setLoading(true);
    try {

      apiManager.EmployeeAniversary().then((response) => {
        setLoading(false)
        setaniversary(response?.data?.data)
      }).catch()
    } catch (error) {
      console.error(error);
    }
  }
  const handlechange = (e) => {
    let data = selectedOption
    const val = e.target.value
    if (val == 7) {
      data = {
        ...data,
        weekly: val
      }
    } else if (val == 30) {
      data = {
        ...data,
        monthly: val,
        yearly: ""
      }
    } else if (val == 365) {
      data = {
        ...data,
        monthly: "",
        yearly: val
      }
    }

    setSelectedOption(data)
  }

  return (
    <>
      <div className="container-fluid">
        <SubHeader location={useLocation()} />
        <SCard tools={tools} />
        <div className="row Dashtop-row">
          <div className="col-2">
            <div className="card">
              <div class="card-top"><div class="card-title">Today Attendance</div></div>
              <Charts option={attendeceData} type="donut" height={350} />
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div class="card-top"><div class="card-title">Employee Overview</div></div>
              {yearlGraphData?.series?.length > 0 && <Charts option={areaChartData} type="bar" height={350} />}
            </div>
          </div>
          <div className="col-5">
            <div className="card">
              <div class="card-top">
                <div class="card-title">Employees By Department </div>
                <div className="selectbtn">
                  <span>Short by :</span>
                  <select name=""
                    onChange={e => handlechange(e)}>
                    <option disabled>{"select"}</option>
                    {keyData?.map(({ Key, value }, i) => (
                      <option key={i} value={value} >{Key}</option>
                    ))}
                  </select>

                </div>
              </div>
              {deparmentData?.series?.length > 0 && <ReactApexChart options={donutoption.options} series={donutoption.series} type="bar" height={350} />}
            </div>
          </div>
        </div>

        <div className="row Dashmiddle-row">
          <div className="col-4">
            <div className="card">
              <CalendarApp />
            </div>
          </div>
          <div className="col-8">
            <div className="card">
              <div className="card-top">
                <div className="card-title">
                  Onboarding Overview
                </div>
                <div className="selectbtn">
                  Short by :  <select name="cars">
                    <option value="">Dec</option>
                    <option value="saab">jan</option>
                    <option value="mercedes">Feb</option>
                  </select>
                </div>
              </div>
              <HrtoolDashoardTable />
            </div>
          </div>

        </div>


        <div className="row Dashbottom-row">
          <div className="col-4">
            <div className="card">
              <div className="card-top">
                <div className="card-title">
                  Birthday / Work Anniversary
                </div>
                {/* <div className="selectbtn">
                  <select name="cars">
                    <option value="">Dec</option>
                    <option value="saab">jan</option>
                    <option value="mercedes">Feb</option>
                  </select>
                </div> */}
              </div>
              {dobemployee.dob_day?.data?.length || dobemployee.dob_month?.data?.length || aniversary.length ?
                <div className="BirthdayAnniversary-box" >
                  <div className="Birthday-day">
                    {dobemployee?.dob_day?.data?.length ?
                      <h5>Today</h5> : <></>}

                    <ul>
                      {dobemployee.dob_day?.data?.map((dob) => (
                        <>
                          <li>
                            <div className="Birthday-text clearfix">
                              <figure> <img src={dob.image ? `${baseURL}/${dob.image}` : profileimg} alt="User" /> </figure>
                              <div className="Birthday-name">
                                <h6>
                                  {dob.firstname}
                                  {dob.middlename  || !dob.middlename === undefined? ` ${dob.middlename}` : ""}
                                  {dob.lastname ? ` ${dob.lastname}` : ""}
                                </h6>

                                <span>UI & UX Desginer</span>
                              </div>
                            </div>
                            <div className="Birthday-info">
                              <div className="Birthday-date">
                                <figure><LiaBirthdayCakeSolid /></figure>
                                <p>{dob.originalDob}</p>
                              </div>
                            </div>
                          </li>

                        </>
                      ))}

                    </ul>
                    <ul>
                      {aniversary?.map((data) => (
                        <li>
                          <div className="Birthday-text clearfix">
                            <figure> <img src={data?.image ? `${baseURL}/${data?.image}` : profileimg} alt="User" /> </figure>
                            <div className="Birthday-name">
                              <h6>  {data.firstname}
                                {data.middlename  ? ` ${data.middlename}` : ""}
                                {data.lastname ? ` ${data.lastname}` : ""}</h6>
                              <span>UI & UX Desginer</span>
                            </div>
                          </div>
                          <div className="Birthday-info Anniversary">
                            <div className="Anniversarytext">1 Year Completed</div>
                            <div className="Birthday-date">
                              {/* <span>Joining:</span> */}
                              <figure><IoBagOutline /></figure>
                              <p>{data?.createdAt?.slice(0, 10)}</p>
                            </div>
                          </div>
                        </li>
                      ))}

                    </ul>
                  </div>
                  <div className="Birthday-day">
                    {dobemployee?.dob_month?.data?.length ?
                      <h5>Upcoming Birthday</h5> : <></>}
                    <ul>
                      {dobemployee?.dob_month?.data?.map((data, i) => (
                        <li>
                          <div className="Birthday-text clearfix">
                            <figure> <img src={data.image ? `${baseURL}/${data.image}` : profileimg} alt="User" /> </figure>
                            <div className="Birthday-name">
                              <h6>
                                {data.firstname}
                                {data.middlename  && !data.middlename ===undefined? ` ${data.middlename}` : ""}
                                {data.lastname ? ` ${data.lastname}` : ""}
                              </h6>

                              <span>{data?.profile}</span>
                            </div>
                          </div>
                          <div className="Birthday-info">
                            <div className="Birthday-date">
                              <figure><LiaBirthdayCakeSolid /></figure>
                              <p>{formatDateCharcter(data.originalDob)}</p>
                            </div>
                          </div>
                        </li>
                      ))}

                    </ul>
                  </div>
                </div>

                : <NoDataFound />
              }
            </div>
          </div>
          <div className="col-4">
            <div className="card">
              <div className="card-top">
                <div className="card-title">
                  Asset Status
                </div>
                <div className="selectbtn">
                  <select name="cars">
                    <option value="">2024</option>
                    <option value="saab">2025</option>
                    <option value="mercedes">2026</option>
                  </select>
                </div>
              </div>
              {totalAssetsCount > 0 && <Charts option={assetsData} type="donut"  />}


              <div className="Asset-info-card">
                <div className="Assetinfo Total-A">
                  <p>Total Inventory</p>
                  <h5>{totalAssetsCount < 10 ? `0${totalAssetsCount}` : totalAssetsCount}</h5>
                </div>
                <div className="Assetinfo Assigned-A">
                  <p>Assigned Asset</p>
                  <h5>{totalassets?.assigned < 10 ? `0${totalassets?.assigned}` : totalassets?.assigned}</h5>
                </div>
                <div className="Assetinfo Unassigned-A">
                  <p>Unassigned Asset</p>
                  <h5>{totalassets?.unassigned < 10 ? `0${totalassets?.unassigned}` : totalassets?.unassigned}</h5>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default Hrtools;
